import React, { FunctionComponent, useEffect } from 'react';
import { Button } from 'assets/components/button';
import type { NativeStackScreenProps } from '@react-navigation/native-stack';
import { Platform, View } from 'react-native';
import {
  LoginStackNavigationProp,
  LoginStackParamList,
} from '../../navigation/LoginNavigation';
import { makeStyles, useTheme } from 'assets/theme';
import { getText } from 'assets/localization/localization';
import { TextField } from 'assets/components/text-field';
import { checkUserStatus, LoginForm } from './login-actions';
import { useForm } from 'assets/form';
import { LoginTestIDs } from './LoginTestIDs';
import { useLoginState } from './login-store';
import { ScreenContainer, Form } from 'assets/layout';
import { Logo } from '../../components/logo/Logo';
import isEmail from 'validator/lib/isEmail';
import { useNavigation } from '@react-navigation/native';
import { useDeepLinkingContext } from 'assets/core/deep-linking';
import * as Linking from 'expo-linking';
import IntroWebHeader from '../../components/landing-header/IntroWebHeader';
import { useCurrentUrl } from 'assets/hooks';

export const LoginEnterEmail: FunctionComponent<
  LoginEnterEmailProps
> = ({}) => {
  const { url } = useCurrentUrl();

  const theme = useTheme();
  const styles = useStyles();
  const methods = useForm<LoginForm>({
    defaultValues: {
      email: '',
    },
  });

  const navigation = useNavigation<LoginStackNavigationProp>();

  const isWeb = Platform.OS === 'web';

  const handleSignUpPress = () => {
    navigation.goBack();
  };

  const { status, error } = useLoginState();

  const handleSubmit = async () => {
    if (!validateInput()) {
      return;
    }

    if (url && url !== '') {
      const { queryParams } = Linking.parse(url);
      if (queryParams) {
        checkUserStatus(methods.getValues().email, navigation, queryParams);
      } else {
        checkUserStatus(methods.getValues().email, navigation);
      }
    } else {
      checkUserStatus(methods.getValues().email, navigation);
    }
  };

  const validateInput = () => {
    const isEmailValid = isEmail(methods.getValues().email.trim());
    if (!isEmailValid) {
      useLoginState.setState({
        error: {
          message: getText('invalid-email-format'),
        },
        status: 'error',
      });
      return false;
    }
    return true;
  };

  useEffect(() => {
    return () => {
      useLoginState.setState({ error: undefined, status: 'idle' });
    };
  }, []);

  return (
    <>
      <View
        style={[
          styles.container,
          {
            backgroundColor: isWeb
              ? theme.palette.gray[50]
              : theme.colors.brandedPrimary,
          },
        ]}
      >
        {isWeb && <IntroWebHeader />}
        <Logo width={isWeb ? 340 : 241} isOnDark={!isWeb} />
        <ScreenContainer
          backgroundColor={
            isWeb ? theme.palette.gray[50] : theme.colors.brandedPrimary
          }
          keyboardVerticalOffset={Platform.OS === 'android' ? -125 : undefined}
        >
          <Form methods={methods}>
            <Form.Alert
              title={error?.message}
              intent="error"
              visible={!!error}
            />
            <Form.Row>
              <Form.Column>
                <TextField
                  isOnDark={Platform.OS !== 'web'}
                  label={getText('email')}
                  disabledBackgroundColor={
                    isWeb
                      ? theme.palette.transparent
                      : theme.colors.brandedPrimary
                  }
                  placeholderColor={
                    isWeb ? theme.palette.gray[500] : theme.palette.white
                  }
                  textColor={
                    isWeb ? theme.palette.black[500] : theme.palette.white
                  }
                  activeBorderColor={
                    isWeb ? theme.palette.gray[500] : theme.palette.white
                  }
                  inactiveBorderColor={
                    isWeb ? theme.palette.gray[300] : theme.palette.white
                  }
                  backgroundColor={
                    isWeb
                      ? theme.palette.transparent
                      : theme.colors.brandedPrimary
                  }
                  labelColor={
                    isWeb ? theme.palette.gray[500] : theme.palette.white
                  }
                  name="email"
                  rules={{
                    required: getText('email-is-required'),
                    validate: {
                      value: () => {
                        return isEmail(methods.getValues().email.trim())
                          ? true
                          : Platform.OS !== 'web'
                          ? getText('invalid-email-format')
                          : getText('email-is-not-valid');
                      },
                    },
                  }}
                  hideRequiredAsterisk={true}
                  onSubmit={methods.handleSubmit(handleSubmit)}
                  disabled={status === 'loading'}
                  testID={LoginTestIDs.usernameInput}
                />
              </Form.Column>
            </Form.Row>
            <Form.Actions>
              <Button
                testID={LoginTestIDs.loginButton}
                onPress={methods.handleSubmit(handleSubmit)}
                hierarchy={isWeb ? 'primary' : 'secondary'}
                loading={status === 'loading'}
                logger={{ id: 'login-button' }}
                style={styles.loginButton}
              >
                {getText('next')}
              </Button>

              <Button
                hierarchy={isWeb ? 'secondary' : 'primary'}
                testID={LoginTestIDs.goBackButton}
                onPress={handleSignUpPress}
                logger={{ id: 'go-back-button' }}
              >
                {getText('go-back')}
              </Button>
            </Form.Actions>
          </Form>
        </ScreenContainer>
      </View>
    </>
  );
};

type LoginEnterEmailProps = NativeStackScreenProps<
  LoginStackParamList,
  'login'
>;

const useStyles = makeStyles((theme) => ({
  container: {
    flex: 1,
  },
  loginButton: {
    padding: theme.getSpacing(1),
    marginBottom: theme.getSpacing(2),
  },
}));
