import React, { FunctionComponent, PropsWithChildren, useEffect } from 'react';
import { Button } from 'assets/components/button';
import type { NativeStackScreenProps } from '@react-navigation/native-stack';
import { Platform, View } from 'react-native';
import {
  LoginStackNavigationProp,
  LoginStackParamList,
} from '../../navigation/LoginNavigation';
import { makeStyles, useTheme } from 'assets/theme';
import { getText } from 'assets/localization/localization';
import { TextField } from 'assets/components/text-field';
import { login, LoginForm } from './login-actions';
import { useForm } from 'assets/form';
import { LoginTestIDs } from './LoginTestIDs';
import { useLoginState } from './login-store';
import { ScreenContainer, Form } from 'assets/layout';
import { useResetPasswordState } from '../reset-password/reset-password-store';
import { Logo } from '../../components/logo/Logo';
import isEmail from 'validator/lib/isEmail';
import { useNavigation } from '@react-navigation/native';
import IntroWebHeader from '../../components/landing-header/IntroWebHeader';

export const Login: FunctionComponent<PropsWithChildren<LoginProps>> = () => {
  const navigation = useNavigation<LoginStackNavigationProp>();
  const { status, error, email } = useLoginState();
  const theme = useTheme();
  const styles = useStyles();
  const methods = useForm<LoginForm>({
    defaultValues: {
      email: '',
      password: '',
    },
  });

  const isWeb = Platform.OS === 'web';

  useEffect(() => {
    if (email) {
      methods.setValue('email', email.trim());
    }
  }, []);

  const handleGoBackPress = () => {
    navigation.navigate('intro');
  };

  const handleForgotPassword = () => {
    useResetPasswordState.setState({ status: 'idle', error: undefined });
    navigation.navigate('reset-link');
  };

  const handleSubmit = async () => {
    let values = {
      email: methods.getValues().email.trim(),
      password: methods.getValues().password,
    };
    await login(values, navigation);
  };

  useEffect(() => {
    return () => {
      useLoginState.setState({ error: undefined, status: 'idle' });
    };
  }, []);

  return (
    <>
      <View
        style={[
          styles.container,
          {
            backgroundColor: isWeb
              ? theme.palette.gray[50]
              : theme.colors.brandedPrimary,
          },
        ]}
      >
        {isWeb && <IntroWebHeader />}
        <Logo width={isWeb ? 340 : 241} isOnDark={!isWeb} />
        <ScreenContainer
          backgroundColor={
            isWeb ? theme.palette.gray[50] : theme.colors.brandedPrimary
          }
          keyboardVerticalOffset={Platform.OS === 'android' ? -150 : 0}
        >
          <View style={styles.credentialsContainer}>
            <Form methods={methods}>
              <Form.Alert
                title={getText('email-password-incorrect-try-forgot-password')}
                intent="error"
                visible={!!error}
              />
              <Form.Row>
                <Form.Column>
                  <TextField
                    isOnDark={Platform.OS !== 'web'}
                    label={getText('email')}
                    type="emailAddress"
                    disabledBorderColor={theme.palette.white}
                    disabledBackgroundColor={
                      isWeb
                        ? theme.palette.transparent
                        : theme.colors.brandedPrimary
                    }
                    placeholderColor={
                      isWeb ? theme.palette.gray[500] : theme.palette.white
                    }
                    textColor={
                      isWeb ? theme.palette.black[500] : theme.palette.white
                    }
                    activeBorderColor={
                      isWeb ? theme.palette.gray[500] : theme.palette.white
                    }
                    inactiveBorderColor={
                      isWeb ? theme.palette.gray[300] : theme.palette.white
                    }
                    backgroundColor={
                      isWeb
                        ? theme.palette.transparent
                        : theme.colors.brandedPrimary
                    }
                    labelColor={
                      isWeb ? theme.palette.gray[500] : theme.palette.white
                    }
                    name="email"
                    rules={{
                      required: getText('email-is-required'),
                      validate: {
                        value: () => {
                          return isEmail(methods.getValues().email.trim())
                            ? true
                            : getText('email-is-not-valid');
                        },
                      },
                    }}
                    hideRequiredAsterisk={true}
                    onSubmit={methods.handleSubmit(handleSubmit)}
                    testID={LoginTestIDs.usernameInput}
                  />
                </Form.Column>
              </Form.Row>
              <Form.Row>
                <Form.Column>
                  <TextField
                    isOnDark={Platform.OS !== 'web'}
                    label={getText('password')}
                    disabledBorderColor={theme.palette.white}
                    disabledBackgroundColor={
                      isWeb
                        ? theme.palette.transparent
                        : theme.colors.brandedPrimary
                    }
                    placeholderColor={
                      isWeb ? theme.palette.gray[500] : theme.palette.white
                    }
                    textColor={
                      isWeb ? theme.palette.black[500] : theme.palette.white
                    }
                    activeBorderColor={
                      isWeb ? theme.palette.gray[500] : theme.palette.white
                    }
                    inactiveBorderColor={
                      isWeb ? theme.palette.gray[300] : theme.palette.white
                    }
                    backgroundColor={
                      isWeb
                        ? theme.palette.transparent
                        : theme.colors.brandedPrimary
                    }
                    labelColor={
                      isWeb ? theme.palette.gray[500] : theme.palette.white
                    }
                    type="password"
                    name="password"
                    rules={{
                      required: getText('password-is-required'),
                    }}
                    hideRequiredAsterisk={true}
                    onSubmit={methods.handleSubmit(handleSubmit)}
                    disabled={status === 'loading'}
                    testID={LoginTestIDs.passwordInput}
                  />
                </Form.Column>
              </Form.Row>
              <Form.Actions>
                <Button
                  testID={LoginTestIDs.loginButton}
                  onPress={methods.handleSubmit(handleSubmit)}
                  hierarchy={isWeb ? 'primary' : 'secondary'}
                  loading={status === 'loading'}
                  logger={{ id: 'login-button' }}
                  style={styles.loginButton}
                >
                  {getText('login')}
                </Button>

                <Button
                  hierarchy={isWeb ? 'secondary' : 'primary'}
                  testID={LoginTestIDs.goBackButton}
                  onPress={handleGoBackPress}
                  logger={{ id: 'go-back-button' }}
                  style={isWeb ? { marginBottom: theme.getSpacing(0.5) } : {}}
                >
                  {getText('go-back')}
                </Button>

                <Button
                  hierarchy={isWeb ? 'secondary' : 'primary'}
                  onPress={handleForgotPassword}
                  logger={{ id: 'forgot-password-button' }}
                  style={{ marginBottom: theme.getSpacing(4) }}
                >
                  {getText('forgot-password')}
                </Button>
              </Form.Actions>
            </Form>
          </View>
        </ScreenContainer>
      </View>
    </>
  );
};

type LoginProps = NativeStackScreenProps<LoginStackParamList, 'login'>;

const useStyles = makeStyles((theme) => ({
  container: {
    flex: 1,
  },
  loginButton: {
    padding: theme.getSpacing(1),
    marginBottom: theme.getSpacing(2),
  },
  credentialsContainer: {
    flex: Platform.OS === 'web' ? 2 : 1,
  },
}));
