import type { NativeStackScreenProps } from '@react-navigation/native-stack';
import { Text } from 'assets/components/text';
import { InternalScreenContainer } from 'assets/layout';
import { getText } from 'assets/localization/localization';
import { makeStyles, useTheme } from 'assets/theme';
import React, { FunctionComponent, PropsWithChildren } from 'react';
import { Linking, View } from 'react-native';
import { TouchableOpacity } from 'react-native-gesture-handler';
import { NewMessageHandler } from '../../../../messages/MessageProps';
import { NewMessage } from '../../../../messages/NewMessage';
import { buildMessageList } from '../../../../messages/messages-actions';
import { AccountStackParamList } from '../../../AccountNavigation';
import { HelpWithPrescription } from './HelpWithPrescription';
import { TechSupport } from './TechSupport';
import { BaseModalHandler } from 'assets/components/base-modal/BaseModal';
import { GenericBottomSheet } from 'assets/components/generic-bottom-sheet/GenericBottomSheet';
import { ExternalLinkIcon } from 'assets/icons';
import { useUserState } from '../../../../../store/user-store';

export const HelpSupport: FunctionComponent<
  PropsWithChildren<AboutAppProps>
> = () => {
  const user = useUserState((state) => state.user);
  const locationId = user?.preferredPharmacyLocationId ?? '';
  const sheetRef = React.useRef<BaseModalHandler>(null);
  const newMessageRef = React.useRef<NewMessageHandler>(null);
  const styles = useStyles();
  const theme = useTheme();

  const handleHelpSupportOnPress = () => {
    sheetRef.current?.show();
  };

  const handleTechSupportOnPress = () => {
    // TO DO
  };

  const handleVisitHelpCenter = async () => {
    await Linking.openURL('https://help.lumistry.com/patients');
  };

  const handleCloseHelpSupport = () => {
    sheetRef.current?.hide();
  };

  const onMessageCreate = async () => {
    await buildMessageList();
  };

  return (
    <>
      <InternalScreenContainer>
        <View style={styles.faqTextContainer}>
          <Text style={styles.title}>{getText('faq')}</Text>
          <TouchableOpacity
            onPress={handleVisitHelpCenter}
            style={{
              marginBottom: theme.getSpacing(1),
              flexDirection: 'row',
            }}
          >
            <Text style={styles.subtitle}>{getText('visit-help-center')}</Text>
            <View
              style={{
                marginTop: theme.getSpacing(1),
                marginLeft: theme.getSpacing(1),
              }}
            >
              <ExternalLinkIcon size={16} color={theme.palette.gray[500]} />
            </View>
          </TouchableOpacity>
        </View>
        <Text style={styles.title}>{getText('contact-help-support')}</Text>
        <View style={styles.subtitleContainer}>
          <TouchableOpacity onPress={handleHelpSupportOnPress}>
            <Text style={styles.subtitle}>
              {getText('prescription-help-copy')}
            </Text>
          </TouchableOpacity>
          <TechSupport />
        </View>
      </InternalScreenContainer>
      <GenericBottomSheet
        title={getText('need-help-prescription')}
        ref={sheetRef}
        height="35%"
      >
        <HelpWithPrescription
          newMessageRef={newMessageRef}
          closeModal={handleCloseHelpSupport}
        />
      </GenericBottomSheet>

      <NewMessage
        locationId={locationId}
        ref={newMessageRef}
        onMessageCreate={onMessageCreate}
      />
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  title: {
    color: theme.palette.black,
    fontSize: 18,
    lineHeight: 28,
    fontFamily: theme.fonts.medium.fontFamily,
    paddingBottom: theme.getSpacing(1),
  },
  subtitle: {
    color: theme.palette.gray[700],
    fontSize: 18,
    lineHeight: 28,
    fontFamily: theme.fonts.regular.fontFamily,
    cursor: 'pointer',
  },
  subtitleContainer: {
    gap: theme.getSpacing(1.5),
  },
  helpTitleContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingBottom: theme.getSpacing(1),
  },
  helpTitleText: {
    paddingLeft: 50,
    color: theme.palette.gray[900],
    fontSize: 18,
    lineHeight: 28,
    textAlign: 'center',
    flex: 1,
  },
  faqTextContainer: {
    marginBottom: theme.getSpacing(3),
    borderBottomColor: theme.palette.gray[200],
    borderBottomWidth: 1,
  },
}));

type AboutAppProps = NativeStackScreenProps<
  AccountStackParamList,
  'help-support'
>;
