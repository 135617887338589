import { AxiosRequestConfig } from 'axios';
import { BaseApiConfiguration, BaseApiService } from 'assets/core/api';
import pharmacyService from './pharmacy-service';
import {
  APP_CONFIG_API_BASE_URL,
  DOMAIN,
  PHARMACY_SLUG,
} from '../common/constants';
import { DeepLinkingConfig } from 'assets/core/deep-linking';

export interface IAppConfigService {
  getDeepLinkingConfig: (
    pharmacyId: string,
    appScheme: string,
    pharmacySlug: string,
  ) => Promise<DeepLinkingConfig>;
}

export class AppConfigService
  extends BaseApiService
  implements IAppConfigService
{
  constructor(
    baseUrl: string,
    config: AxiosRequestConfig = {},
    enableAuth = true,
    baseConfig?: BaseApiConfiguration,
  ) {
    super(baseUrl, config, enableAuth, baseConfig);
  }

  async getDeepLinkingConfig(
    pharmacyId: string,
    pharmacySlug: string,
    appScheme: string,
  ): Promise<DeepLinkingConfig> {
    const response = await pharmacyService.getAppConfig(pharmacyId);

    const config = {
      scheme: appScheme,
      enableSmartBanner: true,
      android: {
        fallbackUrl: response.android_store_link || '',
        package: response.android_identifier || '',
      },
      ios: {
        appId: response.ios_app_id || '',
        fallbackUrl: response.apple_store_link || '',
        appArgument: DOMAIN + '/' + pharmacySlug,
      },
    };
    return config;
  }
}

export default new AppConfigService(APP_CONFIG_API_BASE_URL, undefined, true);
