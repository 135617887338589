import {
  InfoCardDto,
  NormalTimeRangeDto,
  PharmacyLocationDto,
} from '@digitalpharmacist/pharmacy-service-client-axios';
import {
  NavigationProp,
  ParamListBase,
  useIsFocused,
  useNavigation,
} from '@react-navigation/native';
import type { NativeStackScreenProps } from '@react-navigation/native-stack';
import { Icon } from 'assets/components/icon';
import { ListItemLink, ListMenu } from 'assets/components/list-menu';
import {
  HomeAppointmentsIcon,
  HomeMedicationsIcon,
  HomeMessagesIcon,
} from 'assets/icons';
import { ScreenContainer } from 'assets/layout';
import { getText } from 'assets/localization/localization';
import { makeStyles, useTheme } from 'assets/theme';
import React, {
  FunctionComponent,
  PropsWithChildren,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Image, Platform, View } from 'react-native';
import pharmacyService from '../../api/pharmacy-service';
import { LandingHeader } from '../../components/landing-header';
import { StoreSelector } from '../../components/store-selector';
import { PreferredStoreInformation } from '../../components/store-selector/PreferredHomeLocationInformation';
import { StoreSelectorHandler } from '../../components/store-selector/types';
import { VideoOverlay } from '../../components/video-overlay/VideoOverlay';
import { AppNavigationParamList } from '../../navigation/AppNavigation';
import { useAppStateStore } from '../../store/app-store';
import { useUserState } from '../../store/user-store';
import { NewMessage } from '../messages/NewMessage';
import { NewMessageHandler } from '../messages/MessageProps';
import { usePatientRecordState } from '../account/patient/patient-store';
import {
  URL_VIDEO_PATTERN,
  intitialInternalLinkData,
} from '../messages/constants';
import { buildMessageList } from '../messages/messages-actions';
import { InternalLinkData, LinkData } from '../messages/types';
import { loadStores } from '../../actions/app-actions';
import {
  checkIfNotAllowedToEmbedWebDataFrom,
  getDefaultGreeting,
  handleAppointmentLinkClick,
} from '../../utils';
import { Divider } from 'react-native-paper';
import { ConditionVideosSection } from './ConditionVideosSection';
import { refreshPatientRecordState } from '../account/patient/patient-actions';
import { Text } from 'assets/components/text';
import { SHORT_URL_DOMAIN } from '../../common/constants';
import { getOriginalURL } from '../messages/utils';
import { Modal } from 'assets/components/modal';
import { IFrame } from '../messages/IFrame';
import { LinkWindowNative } from '../messages/LinkWindowNative';
import PromoCard from '../../components/PromoCard';

export const Dashboard: FunctionComponent<
  PropsWithChildren<DashboardProps>
> = ({ route }) => {
  const theme = useTheme();
  const styles = useStyles();
  const { user, updateUser } = useUserState();
  const locationId = user?.preferredPharmacyLocationId ?? '';

  const [preferredLocation, setPreferredLocation] =
    useState<PharmacyLocationDto>();
  const [locationHours, setLocationHours] = useState<NormalTimeRangeDto>();
  const [infoCards, setInfoCards] = useState<InfoCardDto[]>();

  const storeSelectorRef = useRef<StoreSelectorHandler>(null);

  const { pharmacyName, stores } = useAppStateStore();
  const { patientRecord, recordsUnderCare, caregiverRequests } =
    usePatientRecordState();

  const [videoLinkData, setVideoLinkData] = useState<InternalLinkData>(
    intitialInternalLinkData,
  );

  const [linkData, setLinkData] = useState<LinkData>({
    link: '',
    isOpened: false,
  });

  const navigation = useNavigation<NavigationProp<ParamListBase>>();

  const handleVideoLinkClick = (link: string) => {
    const url = new URL(link);
    const videoId = url.searchParams.get('videoId');
    const locale = url.searchParams.get('locale') || undefined;

    if (videoId)
      setVideoLinkData({ internalId: videoId, isOpened: true, locale });
  };

  const handleAppointmentsLinkClick = (link: string) => {
    const url = new URL(link);
    const appointmentId = url.searchParams.get('appointment_type_id');
    const location_id = url.searchParams.get('location_id') || undefined;

    navigation.navigate('appointments', {
      screen: 'upcoming',
      params: { appointment_type_id: appointmentId, location_id: location_id },
    });
  };

  async function onLinkClick(link: string) {
    const urlDomain = new URL(link).hostname;
    let newLink = link;
    if (urlDomain.includes(SHORT_URL_DOMAIN)) {
      const response = await getOriginalURL(link);
      newLink = response ?? link;
    }

    if (URL_VIDEO_PATTERN.test(newLink)) {
      handleVideoLinkClick(newLink);
      return;
    }

    if (newLink.includes('appointment') && newLink.includes('appointment_id')) {
      handleAppointmentLinkClick(newLink);
      return;
    }

    if (
      newLink.includes('appointments') &&
      newLink.includes('appointment_type_id')
    ) {
      handleAppointmentsLinkClick(newLink);
      return;
    }

    let isNotAllowedToEmbed: boolean | undefined;
    if (Platform.OS === 'web') {
      isNotAllowedToEmbed = await checkIfNotAllowedToEmbedWebDataFrom(newLink);

      if (isNotAllowedToEmbed) {
        window.open(newLink, '_blank');

        // Not updating linkData (that controls opening links in modal) for links,
        // that are not allowed to be embedded
        return;
      }
    }

    setLinkData((prevValue) => {
      const isSameLink = prevValue.link === newLink ? true : false;
      return {
        ...prevValue,
        link: isSameLink ? '' : newLink,
        isOpened: isSameLink ? false : true,
      };
    });
  }

  function closeLinkWindow() {
    setLinkData((prevValue) => {
      return {
        ...prevValue,
        link: '',
        isOpened: false,
      };
    });
  }

  const isFocused = useIsFocused();
  let defaultGreeting = '';
  const isWeb = Platform.OS === 'web';

  // Set the default greeting when the screen is focused
  if (isFocused) {
    defaultGreeting = getDefaultGreeting();
  }

  // first rendering only
  useEffect(() => {
    if (stores.length < 1) void loadStores();
  }, []);

  const handleOnChangePress = () => {
    storeSelectorRef.current?.show();
  };

  const handleOnLocationChanged = (value: PharmacyLocationDto) => {
    void updateUser({ preferredPharmacyLocationId: value.id });
  };

  const handlePeopleUnderCareItemClick = () => {
    if (caregiverRequests.length > 0 || recordsUnderCare.length > 0) {
      navigation.navigate('people-under-care', {
        screen: 'linked-accounts',
      });
    } else {
      navigation.navigate('people-under-care', {
        screen: 'patient-under-care',
      });
    }
  };

  useEffect(() => {
    void (async () => {
      if (!user?.preferredPharmacyLocationId || !user.patientRecordId) return;

      const location = stores.find(
        (location) => location.id === user.preferredPharmacyLocationId!,
      );
      setPreferredLocation(location);
      const pharmacyDepartments = await pharmacyService.findLocationHours(
        user.preferredPharmacyLocationId,
      );
      const pharmacyInfoCards = await pharmacyService.getAllInfoCards(
        user.preferredPharmacyLocationId,
      );
      setInfoCards(pharmacyInfoCards);

      const date = new Date();
      const pharmacyDepartment = pharmacyDepartments.find(
        (department) => department.department_name === 'Pharmacy',
      );
      const selectedPreferredHours = pharmacyDepartment?.time_ranges.find(
        (timeRange) => timeRange.day - 1 === date.getDay(),
      );

      setLocationHours(selectedPreferredHours);
    })();
  }, [user?.preferredPharmacyLocationId, stores]);

  useEffect(() => {
    if (!route.params?.videoId) return;
    const { videoId, locale } = route.params;
    setVideoLinkData({ internalId: videoId, isOpened: true, locale });
  }, [route.params]);

  const newMessageRef = React.useRef<NewMessageHandler>(null);

  const onMessageCreate = async () => {
    await buildMessageList();
  };

  const getBgColor = () => {
    return Platform.OS === 'web' ? theme.palette.gray[50] : theme.palette.white;
  };

  const [showConditionVideosSection, setShowConditionVideosSection] =
    useState(false);

  useEffect(() => {
    if (isFocused) {
      if (!patientRecord) return;
      void refreshPatientRecordState(patientRecord.id);
    }
  }, [isFocused]);

  useEffect(() => {
    if (!patientRecord) return;
    if (patientRecord.medical_conditions.length > 0) {
      setShowConditionVideosSection(true);
    } else {
      setShowConditionVideosSection(false);
    }
  }, [patientRecord]);

  return (
    <View style={{ flex: 1 }}>
      {Platform.OS !== 'web' && (
        <LandingHeader
          pharmacyName={pharmacyName}
          patientName={patientRecord?.first_name}
          height={148}
        />
      )}
      <ScreenContainer
        style={styles.screenContainer}
        showFooter
        backgroundColor={getBgColor()}
      >
        {Platform.OS === 'web' && (
          <>
            <View style={styles.greetingsContainer}>
              <Text style={styles.greetingText}>{defaultGreeting}</Text>
              {patientRecord?.first_name && (
                <Text style={styles.patientNameText}>
                  {patientRecord.first_name}
                </Text>
              )}
            </View>
            <Divider style={styles.greetingsDivider} />
          </>
        )}
        <ListMenu style={styles.container}>
          <ListItemLink
            onPress={() => {
              navigation.navigate('medications');
            }}
          >
            <View style={styles.listItemContainer}>
              <View style={styles.iconContainer}>
                <Icon icon={HomeMedicationsIcon} size={53} />
              </View>
              <View style={styles.textContainer}>
                <View style={styles.container}>
                  <Text
                    style={isWeb ? styles.headingWeb : styles.headingMobile}
                  >
                    {getText('medications')}
                  </Text>
                </View>
                <Text style={styles.subHeading}>
                  {getText('home-medications-subheading')}
                </Text>
              </View>
            </View>
          </ListItemLink>
          <ListItemLink
            onPress={() => {
              navigation.navigate('messages');
            }}
          >
            <>
              <View style={styles.listItemContainer}>
                <View style={styles.iconContainer}>
                  <Icon icon={HomeMessagesIcon} size={53} />
                </View>
                <View style={styles.textContainer}>
                  <View style={styles.container}>
                    <Text
                      style={isWeb ? styles.headingWeb : styles.headingMobile}
                    >
                      {getText('messages')}
                    </Text>
                  </View>
                  <Text style={styles.subHeading}>
                    {getText('home-messages-subheading')}
                  </Text>
                </View>
              </View>
            </>
          </ListItemLink>
          <ListItemLink
            onPress={() => {
              navigation.navigate('appointments');
            }}
          >
            <>
              <View style={styles.listItemContainer}>
                <View style={styles.iconContainer}>
                  <Icon icon={HomeAppointmentsIcon} size={53} />
                </View>
                <View style={styles.textContainer}>
                  <View style={styles.container}>
                    <Text
                      style={isWeb ? styles.headingWeb : styles.headingMobile}
                    >
                      {getText('appointments')}
                    </Text>
                  </View>
                  <Text style={styles.subHeading}>
                    {getText('home-appointments-subheading')}
                  </Text>
                </View>
              </View>
            </>
          </ListItemLink>
          <ListItemLink
            onPress={() => {
              navigation.navigate('account');
            }}
          >
            <>
              <View style={styles.listItemContainer}>
                <View style={styles.iconContainer}>
                  {/*
      Using Image instead of Icon because of some issues with the Mask component with our current version of React Native SVG.
      We will be updating Expo soon, and will likely be updating this package when we do. Will fix this issue then.
      */}
                  <Image
                    source={require('../../../assets/home-account.png')}
                    style={{ width: 53, height: 53 }}
                  />
                </View>
                <View style={styles.textContainer}>
                  <View style={styles.container}>
                    <Text
                      style={isWeb ? styles.headingWeb : styles.headingMobile}
                    >
                      {getText('account')}
                    </Text>
                  </View>
                  <Text style={styles.subHeading}>
                    {getText('home-account-subheading')}
                  </Text>
                </View>
              </View>
            </>
          </ListItemLink>
          <ListItemLink onPress={() => handlePeopleUnderCareItemClick()}>
            <>
              <View style={styles.listItemContainer}>
                <View style={styles.iconContainer}>
                  {/*
      Using Image instead of Icon because of some issues with the Mask component with our current version of React Native SVG.
      We will be updating Expo soon, and will likely be updating this package when we do. Will fix this issue then.
      */}
                  <Image
                    source={require('../../../assets/home-puc.png')}
                    style={{ width: 53, height: 53 }}
                  />
                </View>
                <View style={styles.textContainer}>
                  <View style={styles.container}>
                    <Text
                      style={isWeb ? styles.headingWeb : styles.headingMobile}
                    >
                      {getText('linked-accounts')}
                    </Text>
                  </View>
                  <Text
                    style={{
                      color: theme.palette.gray[700],
                    }}
                  >
                    {`${getText('caregivers')}, ${getText(
                      'patients-under-care',
                    ).toLowerCase()}`}
                  </Text>
                </View>
              </View>
            </>
          </ListItemLink>
        </ListMenu>
        {infoCards &&
          infoCards.map(
            (card) =>
              card.active && (
                <View
                  key={card.id}
                  style={
                    infoCards.length > 1
                      ? { marginBottom: theme.getSpacing(1) }
                      : Platform.OS === 'web'
                      ? { marginVertical: theme.getSpacing(1) }
                      : { marginBottom: theme.getSpacing(3) }
                  }
                >
                  <PromoCard
                    onLinkClick={() =>
                      card.link_url && onLinkClick(card.link_url)
                    }
                    title={card.title}
                    description={card.summary}
                    icon={card.icon}
                    linkUrl={card.link_url ?? undefined}
                    linkLabel={card.link_label ?? undefined}
                  />
                </View>
              ),
          )}
        {showConditionVideosSection && patientRecord && (
          <ConditionVideosSection />
        )}
        {preferredLocation && (
          <View style={styles.locationContainer}>
            <PreferredStoreInformation
              item={preferredLocation}
              onChangePress={handleOnChangePress}
              openingHours={locationHours}
              showChangeButton={stores.length > 1}
              onSendMessagePress={() => {
                newMessageRef.current?.show();
              }}
            />
            <NewMessage
              locationId={locationId}
              ref={newMessageRef}
              onMessageCreate={onMessageCreate}
            />
            <StoreSelector
              ref={storeSelectorRef}
              options={stores}
              selectedOption={stores.find(
                (x) => x.id === user?.preferredPharmacyLocationId,
              )}
              onChange={handleOnLocationChanged}
              storeInformationShown={false}
            />
          </View>
        )}
      </ScreenContainer>
      <VideoOverlay data={videoLinkData} setData={setVideoLinkData} />
      {Platform.OS === 'web' ? (
        <Modal
          show={linkData.isOpened}
          okButtonProps={{
            onPress: closeLinkWindow,
            logger: { id: 'external-webpage-close-button-modal' },
            text: getText('close'),
          }}
          dismissButtonProps={{
            onPress: closeLinkWindow,
            logger: { id: 'external-webpage-dismiss-button-modal' },
          }}
        >
          <IFrame src={linkData.link} />
        </Modal>
      ) : (
        <LinkWindowNative
          src={linkData.link}
          show={linkData.isOpened}
          onClose={closeLinkWindow}
        />
      )}
    </View>
  );
};

type DashboardProps = NativeStackScreenProps<AppNavigationParamList, 'home'>;

const useStyles = makeStyles((theme) => ({
  screenContainer: {
    backgroundColor: Platform.OS === 'web' ? theme.palette.gray[50] : undefined,
    marginTop: Platform.OS === 'web' ? undefined : theme.getSpacing(2),
  },
  container: {
    marginBottom: theme.getSpacing(1),
  },
  iconContainer: {
    marginRight: theme.getSpacing(2),
  },
  locationContainer: {
    backgroundColor: theme.palette.gray[50],
    paddingTop: theme.getSpacing(3),
  },
  textContainer: {
    flex: 1,
    flexShrink: 1,
    flexBasis: 'auto',
    wordWrap: 'break-word',
    overflowWrap: 'break-word',
  },
  listItemContainer: {
    flexDirection: 'row',
    paddingVertical:
      Platform.OS === 'web' ? theme.getSpacing(2) : theme.getSpacing(1),
    alignItems: 'center',
    paddingLeft: Platform.OS === 'web' ? undefined : theme.getSpacing(1),
    flexWrap: 'wrap',
  },
  subHeading: {
    ...theme.lumistryFonts.label.small.regular,
    color: theme.palette.gray[700],
    marginBottom: Platform.OS === 'web' ? theme.getSpacing(0.5) : undefined,
  },
  headingMobile: {
    ...theme.lumistryFonts.label.large.semiBold,
  },
  headingWeb: {
    ...theme.lumistryFonts.display,
    fontSize: 28,
    fontWeight: '700',
  },
  greetingsContainer: {
    paddingTop: theme.getSpacing(4),
    paddingBottom: theme.getSpacing(3),
  },
  greetingText: {
    ...theme.lumistryFonts.text.xLarge.semiBold,
    color: theme.palette.black,
  },
  patientNameText: {
    color: theme.palette.black,
    ...theme.lumistryFonts.display,
    fontSize: 36,
    fontWeight: '600',
  },
  greetingsDivider: {
    borderBottomWidth: 1.5,
    color: theme.palette.black,
  },
}));
