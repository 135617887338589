import patientService from '../../api/patient-service';
import { useUserState } from '../../store/user-store';
import { SubjectOptionsEnum } from './MessageProps';
import { useMessagesState } from './messages-store';
import {
  AuthorType,
  DirectMessagePatientDto,
} from '@digitalpharmacist/unified-communications-service-client-axios';
import {
  DocumentPickerAsset,
  DocumentPickerResult,
  getDocumentAsync,
} from 'expo-document-picker';
import { UserTyping } from './types';
import UnifiedCommsService from '../../api/unified-comms-service';
import { compare, getMessages } from 'assets/utils/messageUtils';
import { Socket } from 'socket.io-client';
import {
  EmittedUpdatedUserStatus,
  IMessageExtended,
  MessagesPagination,
  Order,
} from 'assets/types/messageTypes';
import { ImagePickerAsset } from 'expo-image-picker';
import { PatientRecordDto } from '@digitalpharmacist/patient-service-client-axios';
import FileStorageService from '../../api/file-storage-service';
import {
  ACCEPTED_MIME_TYPES,
  FILE_SIZE_LIMIT,
  MAX_ATTACHMENTS,
  MESSAGES_TAKE,
} from './constants';
import { getText } from 'assets/localization/localization';

export const setInitialSubject = (
  initialSubject: SubjectOptionsEnum | undefined,
) => {
  useMessagesState.setState({ initialSubject });
};

export const setShowMessageModal = (showModal: boolean) => {
  useMessagesState.setState({ showModal });
};

export const setSelectedConversation = (selectedConversation: string) => {
  useMessagesState.setState({ selectedConversation: selectedConversation });
};

export const removeSelectedConversation = () => {
  useMessagesState.setState({ selectedConversation: null });
};

export const setViewedConversationsSet = (
  viewedConversationsSet: Set<string>,
) => {
  useMessagesState.setState({ viewedConversationsSet });
};

export const setSelectedMessages = (selectedMessages: IMessageExtended[]) => {
  useMessagesState.setState({ selectedMessages: selectedMessages });
};

export const removeSelectedMessages = () => {
  useMessagesState.setState({ selectedMessages: [] });
};

export const setRawConversations = (
  rawConversations: DirectMessagePatientDto[],
) => {
  useMessagesState.setState({ rawConversations: rawConversations });
};

export const openNewChatModal = () => {
  useMessagesState.setState({ isOpenNewChatModal: true });
};

export const closeNewChatModal = () => {
  useMessagesState.setState({ isOpenNewChatModal: false });
};

export const setIsLoading = (isLoading: boolean) => {
  useMessagesState.setState({ isLoading });
};

export const setComposerHeight = (composerHeight: number) => {
  useMessagesState.setState({ composerHeight });
};

export const setIncomeMessage = async (
  pharmacyId: string,
  locationId: string,
  locationPatientId: string,
  conversationId: string,
  authorType: AuthorType,
) => {
  const { failedMessagesInConversation } = useMessagesState.getState();

  const messagesResult = await getMessages({
    pharmacyId: pharmacyId,
    locationId: locationId,
    locationPatientId: locationPatientId,
    conversationId: conversationId,
    UnifiedCommsService: UnifiedCommsService,
    FileStorageService: FileStorageService,
    skip: 0,
    take: MESSAGES_TAKE,
    failedMessagesInConversation,
    isPharmacyApp: false,
  });

  const isMessageNew = false;

  useMessagesState.setState((state) => {
    const isPatientInSameConversation =
      state.selectedConversation === conversationId;

    let newConversations: DirectMessagePatientDto[] = [];
    const newState = {
      ...state,
    };

    if (isPatientInSameConversation) {
      newState.selectedMessages = messagesResult.messages;
    }

    const conversationIndex = state.rawConversations.findIndex((patient) => {
      return conversationId == patient.conversation_id;
    });

    if (conversationIndex > -1) {
      newConversations = [...state.rawConversations];

      const defineWhetherMessageIsRead = () => {
        if (authorType === AuthorType.Patient) {
          return true;
        } else {
          return isPatientInSameConversation;
        }
      };

      if (messagesResult.messages[0].createdAt instanceof Date) {
        newConversations[conversationIndex] = {
          ...newConversations[conversationIndex],
          most_recent_qualifying_message: messagesResult.messages[0].text,
          most_recent_qualifying_message_date:
            messagesResult.messages[0].createdAt.toISOString(),
          patient_viewed_all_messages: defineWhetherMessageIsRead(),
        };
      }
    }

    const sortedConversations = newConversations.sort(
      (currentConversation, nextConversation) =>
        compare(
          currentConversation,
          nextConversation,
          'most_recent_qualifying_message_date',
          Order.DESC,
          true,
        ),
    );

    if (newConversations.length > 0) {
      newState.rawConversations = sortedConversations;
    }

    const newViewedConversationsSet = new Set(
      newConversations
        .filter((conversation) => conversation.patient_viewed_all_messages)
        .map((conversation) => conversation.conversation_id),
    );

    newState.viewedConversationsSet = newViewedConversationsSet;

    return newState;
  });

  return { isMessageNew };
};

export const setCounts = (count: { unread: number }) => {
  useMessagesState.setState((state) => {
    const newState = { ...state };
    if (count.unread > 0) {
      return { ...newState, count };
    }
    return { ...state, count: {} };
  });
};

export const setUpdatedUserStatus = async (
  updatedUserStatus: EmittedUpdatedUserStatus,
) => {
  const user = useUserState.getState().user;

  if (user?.preferredPharmacyLocationId && user.preferredPharmacyLprId) {
    const rawConversationsData: DirectMessagePatientDto[] =
      await UnifiedCommsService.getAllConversationsByPatientAndLocation(
        user.preferredPharmacyLocationId,
        user.preferredPharmacyLprId,
      );

    const sortedConversationsData = rawConversationsData.sort(
      (currentConversation, nextConversation) =>
        compare(
          currentConversation,
          nextConversation,
          'most_recent_qualifying_message_date',
          Order.DESC,
          true,
        ),
    );

    const updatedConversation = sortedConversationsData.find((conversation) => {
      return conversation.conversation_id === updatedUserStatus.conversation_id;
    });

    if (updatedConversation) {
      updatedConversation.patient_viewed_all_messages =
        updatedUserStatus.patient_viewed_all_messages;
      updatedConversation.pharmacy_viewed_all_messages =
        updatedUserStatus.pharmacy_viewed_all_messages;
    }

    const newViewedConversations = sortedConversationsData
      .filter((conversation) => conversation.patient_viewed_all_messages)
      .map((conversation) => conversation.conversation_id);

    const newViewedConversationsSet = new Set(newViewedConversations);
    setViewedConversationsSet(newViewedConversationsSet);
    setRawConversations(sortedConversationsData);
  }
};

export const buildMessageList = async (): Promise<void> => {
  const user = useUserState.getState().user;
  if (user?.patientRecordId) {
    const patientRecord = await patientService.findPatientRecord(
      user.patientRecordId,
    );

    const locationPatientRecord = patientRecord.location_patient_records.find(
      (record) => record.location_id === user.preferredPharmacyLocationId,
    );

    if (locationPatientRecord) {
      const rawConversationsData =
        await UnifiedCommsService.getAllConversationsByPatientAndLocation(
          locationPatientRecord.location_id,
          locationPatientRecord.id,
        );

      const sortedConversationsData = rawConversationsData.sort(
        (currentConversation, nextConversation) =>
          compare(
            currentConversation,
            nextConversation,
            'most_recent_qualifying_message_date',
            Order.DESC,
            true,
          ),
      );

      const newViewedConversations = sortedConversationsData
        .filter((conversation) => conversation.patient_viewed_all_messages)
        .map((conversation) => conversation.conversation_id);

      const newViewedConversationsSet = new Set(newViewedConversations);
      setViewedConversationsSet(newViewedConversationsSet);
      setRawConversations(sortedConversationsData);
    } else {
      setRawConversations([]);
    }
  }
};

export const setSocket = (socket: Socket | undefined) => {
  useMessagesState.setState({ socket });
};

export const setTypingMember = (typingMember: UserTyping | null) => {
  useMessagesState.setState({ typingMember });
};

export const setNewMessageDocumentAttachments = (
  documents: DocumentPickerAsset[],
) => {
  useMessagesState.setState({ newMessageAttachmentsDocuments: documents });
};

export const setNewMessageImagesAttachments = (images: ImagePickerAsset[]) => {
  useMessagesState.setState({ newMessageAttachmentsImages: images });
};

export const addFailedMessage = (
  conversationId: string,
  failedMessage: IMessageExtended,
) => {
  useMessagesState.setState((state) => {
    const newState = { ...state };

    if (newState.failedMessagesInConversation[conversationId]) {
      newState.failedMessagesInConversation[conversationId].push(failedMessage);
    } else {
      newState.failedMessagesInConversation[conversationId] = [failedMessage];
    }

    return newState;
  });
};

export const removeFailedMessage = (
  conversationId: string,
  message: IMessageExtended,
) => {
  useMessagesState.setState((state) => {
    const newState = { ...state };

    if (newState.failedMessagesInConversation[conversationId]) {
      const newFailedMessages = newState.failedMessagesInConversation[
        conversationId
      ].filter((item) => item._id !== message._id);

      newState.failedMessagesInConversation[conversationId] = newFailedMessages;
    }
    return newState;
  });
};

export const setCurrentPatientRecord = (
  currentPatientRecord: PatientRecordDto,
) => {
  useMessagesState.setState({ currentPatientRecord });
};

export const setMessagesPagination = (pagination: MessagesPagination) => {
  useMessagesState.setState({ messagesPagination: pagination });
};

export const setErrorMessage = (message: string) => {
  useMessagesState.setState({ errorMessage: message });
};

export const chooseFile = async (isReplacement = false) => {
  const docsResult: DocumentPickerResult = await getDocumentAsync({
    multiple: false,
    copyToCacheDirectory: false,
  });

  useMessagesState.setState((state) => {
    const newState = { ...state };
    const newMessageAttachmentsDocuments =
      newState.newMessageAttachmentsDocuments;
    const newMessageAttachmentsImages = newState.newMessageAttachmentsImages;

    if (docsResult.canceled) {
      return newState;
    }

    const fileSize = docsResult.assets.reduce(
      (acc, item) => acc + (item.size ?? 0),
      0,
    );

    if (fileSize > FILE_SIZE_LIMIT) {
      newState.errorMessage = getText('file-too-large');
      return newState;
    }

    const invalidMimeType = docsResult.assets.some(
      (asset) =>
        !asset.mimeType || !ACCEPTED_MIME_TYPES.includes(asset.mimeType),
    );

    if (invalidMimeType) {
      newState.errorMessage = getText('patient-message-invalid-file-type');
      return newState;
    }

    let totalAttachments;
    if (isReplacement) {
      totalAttachments = docsResult.assets;
    } else {
      totalAttachments = [
        ...newMessageAttachmentsDocuments,
        ...docsResult.assets,
      ];
    }

    const uniqueNames: string[] = [];
    const uniqueAttachments = totalAttachments.filter((attachment) => {
      const isDuplicate = uniqueNames.includes(attachment.name);

      if (!isDuplicate) {
        uniqueNames.push(attachment.name);
        return true;
      }

      return false;
    });

    const documentsAndImagesAmount =
      newMessageAttachmentsImages.length + uniqueAttachments.length;

    if (documentsAndImagesAmount > MAX_ATTACHMENTS) {
      newState.errorMessage = getText('patient-message-file-limit');
    } else {
      newState.newMessageAttachmentsDocuments = uniqueAttachments;
      newState.errorMessage = '';
    }

    return newState;
  });
};

export const removeDocumentAttachment = (name: string) => {
  useMessagesState.setState((state) => {
    const newState = { ...state };
    const newMessageAttachmentsDocuments =
      newState.newMessageAttachmentsDocuments;

    const documentsList = newMessageAttachmentsDocuments.filter(
      (attachment: DocumentPickerAsset) => {
        if (attachment.name === name) {
          return false;
        }
        return true;
      },
    );

    newState.newMessageAttachmentsDocuments = documentsList;
    return newState;
  });
};

export const clearAttachments = () => {
  useMessagesState.setState({
    newMessageAttachmentsDocuments: [],
    newMessageAttachmentsImages: [],
  });
};
