import React from 'react';
import { DeepLinkingConfig } from '../types';

export type DeepLinkingContextType = {
  deepLinkingConfig: DeepLinkingConfig;
  setDeepLinkingConfig: (override: Partial<DeepLinkingConfig>) => void;
  launchApp: (
    redirectUrl: string,
    timeout?: number,
    bypassDeepLinking?: boolean,
  ) => void;
};

export const DeepLinkingContext = React.createContext<DeepLinkingContextType>({
  deepLinkingConfig: {} as DeepLinkingConfig,
  setDeepLinkingConfig: () => {},
  launchApp: () => {},
});

export const DeepLinkingConsumer = DeepLinkingContext.Consumer;
