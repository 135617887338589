import React, { FunctionComponent, useEffect, useState } from 'react';
import { makeStyles, useTheme } from 'assets/theme';
import { Pressable, View } from 'react-native';
import { Logo } from '../../components/logo/Logo';
import { ScreenContainer } from 'assets/layout';
import { getText } from 'assets/localization/localization';
import { Button } from 'assets/components/button';
import {
  isAndroidBrowser,
  isIOSBrowser,
  useDeepLinkingContext,
} from 'assets/core/deep-linking';
import { Divider } from 'react-native-paper';
import * as Linking from 'expo-linking';
import { useAppStateStore } from '../../store/app-store';
import {
  FacebookIcon,
  InstagramIcon,
  NoAppReadyIcon,
  TwitterXIcon,
} from 'assets/icons';
import { useCurrentUrl } from 'assets/hooks';
import { Text } from 'assets/components/text';
import { Icon } from 'assets/components/icon';

export const GetTheApp: FunctionComponent = () => {
  const theme = useTheme();
  const styles = useStyles();
  const { pharmacy } = useAppStateStore();
  const { launchApp } = useDeepLinkingContext();
  const { url } = useCurrentUrl();
  const { deepLinkingConfig } = useDeepLinkingContext();

  const handleGetTheApp = async () => {
    if (isIOSBrowser()) {
      await Linking.openURL(deepLinkingConfig.ios.fallbackUrl);
      return;
    }
    if (isAndroidBrowser()) {
      await Linking.openURL(deepLinkingConfig.android.fallbackUrl);
      return;
    }
  };

  const handleOpenInApp = () => {
    const { path, queryParams } = Linking.parse(url);
    const queryParamsStringified = new URLSearchParams(
      queryParams as Record<string, string>,
    ).toString();

    launchApp([path, queryParamsStringified].join('?'), 1000, true);
  };
  const NoAppAvailable: FunctionComponent = () => {
    return (
      <>
        <View style={styles.noAppIcon}>
          <Icon color={theme.colors.brandedText} icon={NoAppReadyIcon} />
        </View>
        <Text style={styles.noAppText}>{getText('check-back-soon')}</Text>
        <View style={{ marginTop: theme.getSpacing(3), alignItems: 'center' }}>
          <Text style={styles.subscriptionText}>
            {getText('check-back-soon-description')}
          </Text>
        </View>
        <Text
          style={[styles.subscriptionText, { marginTop: theme.getSpacing(3) }]}
        >
          {getText('thank-you-patience')}
        </Text>
      </>
    );
  };

  const AppAvailable: FunctionComponent = () => {
    return (
      <>
        <Text style={styles.getAppText}>{getText('get-mobile-app')}</Text>
        <View style={{ marginTop: theme.getSpacing(3), alignItems: 'center' }}>
          <Text style={styles.subscriptionText}>
            {getText('manage-subscription')}
          </Text>
        </View>
        <View style={styles.buttonWrapper}>
          <Button
            style={[styles.button]}
            hierarchy={'primary'}
            onPress={handleGetTheApp}
            logger={{ id: 'download-the-app-button' }}
            testID="download-the-app-button"
          >
            {getText('download-the-app')}
          </Button>
          <Pressable onPress={handleOpenInApp} testID="open-in-app-button">
            <Text style={styles.openInAppButton}>{getText('open-in-app')}</Text>
          </Pressable>
        </View>
      </>
    );
  };

  return (
    <ScreenContainer
      backgroundColor={theme.palette.white}
      style={{
        flex: 1,
      }}
    >
      <View style={styles.container}>
        <View style={{ justifyContent: 'center' }}>
          <View>
            <Logo width={325} height={75} isOnDark={false} />
            <Divider style={styles.marginVertical1} />
          </View>
        </View>
        <View
          style={{
            flex: 3,
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {pharmacy.app_is_active ? <AppAvailable /> : <NoAppAvailable />}
        </View>
        <View style={{ justifyContent: 'flex-end', flex: 1 }}>
          <Footer />
        </View>
      </View>
    </ScreenContainer>
  );
};

const Footer: FunctionComponent = () => {
  const theme = useTheme();
  const { pharmacy } = useAppStateStore();

  const footerStyle = useStyles();

  return (
    <View style={footerStyle.footerContainer}>
      <View style={footerStyle.pharmacyNameWrapper}>
        <Text
          style={footerStyle.pharmacyNameText}
        >{`${pharmacy.full_name}`}</Text>
      </View>
      <View style={footerStyle.iconWrapper}>
        <View>
          <Icon
            strokeWidth={0}
            size={24}
            color={theme.palette.gray[600]}
            icon={FacebookIcon}
          />
        </View>
        <View>
          <Icon
            size={24}
            strokeWidth={0}
            color={theme.palette.gray[600]}
            icon={InstagramIcon}
          />
        </View>
        <View>
          <Icon size={24} color={theme.palette.gray[600]} icon={TwitterXIcon} />
        </View>
      </View>
    </View>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    flex: 1,
    marginLeft: theme.getSpacing(2),
    marginRight: theme.getSpacing(2),
    marginTop: theme.getSpacing(1),
  },
  marginVertical1: {
    marginTop: theme.getSpacing(1),
    marginBottom: theme.getSpacing(1),
  },
  footerContainer: {
    padding: theme.getSpacing(2),
    backgroundColor: theme.palette.gray[100],
  },
  pharmacyNameWrapper: {
    alignSelf: 'center',
    maxWidth: 200,
  },
  pharmacyNameText: {
    ...theme.lumistryFonts.text.large.regular,
    fontWeight: '500',
    color: theme.palette.gray[700],
    textAlign: 'center',
  },
  iconWrapper: {
    alignSelf: 'center',
    maxWidth: 200,
    flexDirection: 'row',
    gap: theme.getSpacing(2),
    marginTop: theme.getSpacing(2),
    marginBottom: theme.getSpacing(2),
  },
  button: {
    borderColor: theme.palette.gray[300],
    paddingTop: theme.getSpacing(0.5),
    paddingBottom: theme.getSpacing(0.5),
  },
  buttonWrapper: {
    width: 240,
    alignSelf: 'center',
    marginTop: theme.getSpacing(3),
  },
  subscriptionText: {
    ...theme.lumistryFonts.text.large.regular,
    color: theme.palette.black,
    textAlign: 'center',
  },
  getAppText: {
    ...theme.lumistryFonts.label.xxLarge.semiBold,
    lineHeight: 32,
    fontWeight: '500',
    color: theme.palette.gray[800],
  },
  noAppText: {
    ...theme.lumistryFonts.label.xxLarge.semiBold,
    fontWeight: '500',
    color: theme.palette.gray[800],
    marginTop: theme.getSpacing(3),
  },
  noAppIcon: {
    marginTop: theme.getSpacing(1),
    marginLeft: theme.getSpacing(3),
  },
  openInAppButton: {
    ...theme.lumistryFonts.text.large.regular,
    color: theme.palette.primary[600],
    textAlign: 'center',
    marginTop: theme.getSpacing(3),
  },
}));
