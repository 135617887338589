import { v4 } from 'uuid';
import { DocumentPickerAsset } from 'expo-document-picker';
import { ImagePickerAsset } from 'expo-image-picker';
import { LocationCategory } from '@digitalpharmacist/file-storage-service-client-axios';
import { logError } from 'assets/logging/logger';
import { IUploadFilesResult } from 'assets/types/messageTypes';
import UnifiedCommsService from '../../api/unified-comms-service';
import { embeddingControlHeaders } from './constants';
import FileStorageService from '../../api/file-storage-service';

export function findHeaderThatDisallowsEmbedding(
  headers: Record<string, string>,
) {
  for (const header of embeddingControlHeaders) {
    if (
      header.name in headers &&
      new RegExp(header.directiveDisallowingEmbedding, 'i').test(
        headers[header.name],
      )
    ) {
      return headers[header.name];
    }
  }
  return null;
}

export const getOriginalURL = async (shortUrl: string) => {
  try {
    const { originalURL } =
      await UnifiedCommsService.shortioGetLinkInfo(shortUrl);
    return originalURL;
  } catch (error) {
    logError(new Error('Failed to get original URL:', error as Error));
  }
};

export async function markAsRead(
  locationId: string,
  locationPatientId: string,
  conversationId: string,
  pharmacyViewedAllMessages: boolean,
) {
  return await UnifiedCommsService.updateUserViewedStatus(
    locationId,
    locationPatientId,
    conversationId,
    {
      patient_viewed_all_messages: true,
      pharmacy_viewed_all_messages: pharmacyViewedAllMessages,
    },
  );
}

async function uploadFiles(
  locationId: string,
  pharmacyId: string,
  files: DocumentPickerAsset[] | undefined,
): Promise<IUploadFilesResult> {
  const result: IUploadFilesResult = {
    isError: false,
    filesData: [],
  };

  if (!files || !files.length) {
    return result;
  }

  for (const file of files) {
    try {
      const newName = v4();
      const extension = FileStorageService.getFileExtension(file.name);
      const fileName = `${newName}.${extension}`;

      await FileStorageService.uploadFile(
        LocationCategory.DirectMessage,
        locationId,
        fileName,
        pharmacyId,
        file,
      );

      result.filesData.push({
        name: file.name,
        stored_filename: fileName,
      });
    } catch (error) {
      console.error(`Error uploading file ${file.name}. Error: `, error);
      result.isError = true;
    }
  }

  return result;
}

const uploadImages = async (
  pharmacyId: string,
  locationId: string,
  images: ImagePickerAsset[],
) => {
  const result: IUploadFilesResult = {
    isError: false,
    filesData: [],
  };

  for (const image of images) {
    try {
      const newName = v4();
      const fileName = `${newName}.png`;

      await FileStorageService.uploadFile(
        LocationCategory.DirectMessage,
        locationId,
        fileName,
        pharmacyId,
        image.uri,
      );

      result.filesData.push({
        name: fileName,
        stored_filename: fileName,
      });
    } catch (error) {
      console.error(`Error uploading image. Error: `, error);
      result.isError = true;
    }
  }
  return result;
};

interface IUploadAttachments {
  pharmacyId: string;
  locationId: string;
  newMessageAttachmentsDocuments: DocumentPickerAsset[];
  newMessageAttachmentsImages: ImagePickerAsset[];
}
export const uploadAttachments = async (attachments: IUploadAttachments) => {
  const {
    pharmacyId,
    locationId,
    newMessageAttachmentsDocuments,
    newMessageAttachmentsImages,
  } = attachments;
  const result: IUploadFilesResult = {
    isError: false,
    filesData: [],
  };

  if (newMessageAttachmentsDocuments.length) {
    const documentsUploadResult = await uploadFiles(
      locationId,
      pharmacyId,
      newMessageAttachmentsDocuments,
    );

    result.isError = documentsUploadResult.isError;
    result.filesData = documentsUploadResult.filesData;
  }

  if (newMessageAttachmentsImages.length) {
    const imagesUploadResult = await uploadImages(
      pharmacyId,
      locationId,
      newMessageAttachmentsImages,
    );

    if (imagesUploadResult.isError) {
      result.isError = true;
    }
    result.filesData = [...result.filesData, ...imagesUploadResult.filesData];
  }

  return result;
};
