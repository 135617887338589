import { ListItemLink, ListMenu } from 'assets/components/list-menu';
import { EmailIcon, FilePlusIcon, ImageIcon } from 'assets/icons';
import { getText } from 'assets/localization/localization';
import { Text } from 'assets/components/text';
import React from 'react';
import { FunctionComponent } from 'react';
import { Platform, View } from 'react-native';
import { makeStyles, useTheme } from 'assets/theme';
import { useUserState } from '../../store/user-store';
import UnifiedCommsService from '../../api/unified-comms-service';
import { DirectMessagePatientDto } from '@digitalpharmacist/unified-communications-service-client-axios';
import {
  chooseFile,
  setErrorMessage,
  setNewMessageImagesAttachments,
} from './messages-actions';
import { useMessagesState } from './messages-store';
import { Alert } from 'assets/components/alert';
import * as ImagePicker from 'expo-image-picker';
import { imagePickerUtils } from '../../utils';
import { Icon } from 'assets/components/icon';
import { FILE_SIZE_LIMIT, MAX_ATTACHMENTS } from './constants';

export const ChatBoxActions: FunctionComponent<ChatBoxActionProps> = ({
  conversation,
  onClose,
}) => {
  const styles = useStyles();
  const theme = useTheme();
  const user = useUserState((state) => state.user);
  const newMessageAttachmentsImages = useMessagesState(
    (state) => state.newMessageAttachmentsImages,
  );
  const newMessageAttachmentsDocuments = useMessagesState(
    (state) => state.newMessageAttachmentsDocuments,
  );
  const errorMessage = useMessagesState((state) => state.errorMessage);
  const locationId = user?.preferredPharmacyLocationId;

  const onChooseFile = async () => {
    await chooseFile();
    onClose(false);
  };

  const onAddImageClick = async () => {
    // No permissions request is necessary for launching the image library
    const result: ImagePicker.ImagePickerResult =
      await ImagePicker.launchImageLibraryAsync({
        mediaTypes: ImagePicker.MediaTypeOptions.Images,
        allowsEditing: false,
      });

    if (result.canceled) {
      return;
    }

    let totalSize = 0;
    if (Platform.OS === 'web') {
      totalSize = await imagePickerUtils.getImagesSizeInWeb(result.assets);
    } else {
      totalSize = await imagePickerUtils.getImagesSizeInMobile(result.assets);
    }

    if (totalSize > FILE_SIZE_LIMIT) {
      setErrorMessage(getText('file-too-large'));
      return;
    }

    const totalImages = [...newMessageAttachmentsImages, ...result.assets];

    const uniqueUris: string[] = [];
    const uniqueImagesAttachments = totalImages.filter((image) => {
      const isDuplicate = uniqueUris.includes(image.uri);

      if (!isDuplicate) {
        uniqueUris.push(image.uri);
        return true;
      }

      return false;
    });

    const documentsAndImagesAmount =
      newMessageAttachmentsDocuments.length + uniqueImagesAttachments.length;

    if (documentsAndImagesAmount > MAX_ATTACHMENTS) {
      setErrorMessage(getText('patient-message-file-limit'));
    } else {
      setNewMessageImagesAttachments(uniqueImagesAttachments);
      onClose(false);
    }
  };

  const markAsUnread = async () => {
    if (locationId) {
      await UnifiedCommsService.updateUserViewedStatus(
        locationId,
        conversation.location_patient_id,
        conversation.conversation_id,
        {
          patient_viewed_all_messages: false,
          pharmacy_viewed_all_messages:
            conversation.pharmacy_viewed_all_messages,
        },
      );
    }

    onClose(true);
  };

  return (
    <View style={{ margin: theme.getSpacing(1) }}>
      {errorMessage && <Alert intent="error" title={errorMessage} />}
      <ListMenu>
        <ListItemLink onPress={onChooseFile} showIcon={false}>
          <View style={styles.listItemContainer}>
            <Icon
              color={theme.palette.primary[600]}
              size={20}
              icon={FilePlusIcon}
            />
            <Text style={styles.textTitle}>{getText('add-file')}</Text>
          </View>
        </ListItemLink>
        <ListItemLink onPress={onAddImageClick} showIcon={false}>
          <View style={styles.listItemContainer}>
            <Icon
              color={theme.palette.primary[600]}
              size={20}
              icon={ImageIcon}
            />
            <Text style={styles.textTitle}>{getText('add-image')}</Text>
          </View>
        </ListItemLink>
        <ListItemLink
          onPress={markAsUnread}
          showIcon={false}
          showDivider={false}
        >
          <View style={styles.listItemContainer}>
            <Icon
              color={theme.palette.primary[600]}
              size={20}
              icon={EmailIcon}
              strokeWidth={1.5}
            />
            <Text style={styles.textTitle}>{getText('mark-unread')}</Text>
          </View>
        </ListItemLink>
      </ListMenu>
    </View>
  );
};

interface ChatBoxActionProps {
  conversation: DirectMessagePatientDto;
  onClose: (closeWindow: boolean) => void;
}

const useStyles = makeStyles((theme) => ({
  textTitle: {
    color: theme.palette.primary[600],
    fontSize: 16,
    fontWeight: '500',
    marginLeft: theme.getSpacing(0.5),
    marginVertical: theme.getSpacing(1),
  },

  listItemContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignContent: 'center',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));
