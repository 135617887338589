import { Text } from 'assets/components/text';
import { FunctionComponent } from 'react';
import { TouchableOpacity, View } from 'react-native';
import { AttachmentIcon, FileIcon, TrashIcon } from 'assets/icons';
import theme, { makeStyles } from 'assets/theme';
import { chooseFile, removeDocumentAttachment } from './messages-actions';
import { useMessagesState } from './messages-store';

export const AttachFiles: FunctionComponent<AttachFilesProps> = () => {
  const styles = useStyles();

  const newMessageAttachmentsDocuments = useMessagesState(
    (state) => state.newMessageAttachmentsDocuments,
  );

  const fileName =
    newMessageAttachmentsDocuments.length > 0
      ? newMessageAttachmentsDocuments[0].name
      : '';

  const onSelectFile = async () => {
    await chooseFile();
  };

  const onRemove = () => {
    removeDocumentAttachment(fileName);
  };

  const onReplace = async () => {
    await chooseFile(true);
  };

  const addDocument = (
    <TouchableOpacity
      onPress={onSelectFile}
      style={{
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <AttachmentIcon color={theme.palette.primary[600]} size={20} />
      <Text style={[{ marginLeft: 8 }, styles.primaryColor]}>
        Attach Files...
      </Text>
    </TouchableOpacity>
  );

  const existingDocument = (
    <View
      style={{
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      <View
        style={{
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        <FileIcon size={24} color={theme.palette.primary[600]} />
        <Text style={styles.primaryColor}>{fileName}</Text>
      </View>
      <View
        style={{
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        <TouchableOpacity onPress={onReplace}>
          <Text
            style={[styles.primaryColor, { marginLeft: 28, marginRight: 8 }]}
          >
            {'Replace'}
          </Text>
        </TouchableOpacity>
        <TouchableOpacity onPress={onRemove}>
          <TrashIcon size={20} color={theme.palette.gray[500]} />
        </TouchableOpacity>
      </View>
    </View>
  );

  return (
    <View
      style={{
        flexDirection: 'row',
        justifyContent: 'flex-start',
        marginBottom: 12,
      }}
    >
      {newMessageAttachmentsDocuments.length > 0
        ? existingDocument
        : addDocument}
    </View>
  );
};

interface AttachFilesProps {}

const useStyles = makeStyles((theme) => ({
  primaryColor: {
    color: theme.palette.primary[600],
  },
}));
