import { AxiosRequestConfig } from 'axios';
import { UNIFIED_COMMS_SERVICE_BASE_URL } from '../common/constants';
import { BaseApiConfiguration, BaseApiService } from 'assets/core/api';
import {
  DefaultApi as UnifiedCommsClient,
  ConversationDto,
  DirectMessageDto,
  UpdateUserViewedStatusDto,
  CreateConversationDto,
  DirectMessagePatientDto,
  AddDirectMessageDto,
  DirectMessageExtendedResponseDto,
  UpdateUserNotificationDto,
  UserNotificationListDto,
  UrlResponseHeadersDto,
  ShortLinkInfoDto,
  Order,
  SupportedOrderBy,
} from '@digitalpharmacist/unified-communications-service-client-axios';
import { AXIOS_DEFAULT_REQUEST_CONFIG } from '../common/axios-default-request-config';

export interface IUnifiedCommsService {
  checkService(): Promise<any>;
  getAllConversationsByPatientAndLocation(
    locationId: string,
    locationPatientId: string,
    orderBy?: SupportedOrderBy,
    order?: Order,
    limit?: number,
  ): Promise<DirectMessagePatientDto[]>;
  getAllMessagesByConversation(
    locationId: string,
    locationPatientId: string,
    conversationId: string,
    skip?: number,
    take?: number,
  ): Promise<DirectMessageExtendedResponseDto>;
  updateUserViewedStatus(
    locationId: string,
    locationPatientId: string,
    conversationId: string,
    updateUserViewedStatusDto: UpdateUserViewedStatusDto,
  ): Promise<ConversationDto>;
  createConversation(
    xPharmacyId: string,
    locationId: string,
    locationPatientId: string,
    createConversationDto: CreateConversationDto,
  ): Promise<ConversationDto>;
  createMessage(
    xPharmacyId: string,
    locationId: string,
    locationPatientId: string,
    conversationId: string,
    addDirectMessageDto: AddDirectMessageDto,
  ): Promise<DirectMessageDto>;
  getConversation(
    locationId: string,
    locationPatientId: string,
    conversationId: string,
  ): Promise<DirectMessagePatientDto>;
  registerPushToken(user_id: string, push_token: string): Promise<void>;
  unregisterPushToken(user_id: string, push_token: string): Promise<void>;
  getNotifications(user_id: string): Promise<UserNotificationListDto>;
  updateUserNotification(
    user_id: string,
    notification_id: string,
    updateUserNotificationDto: UpdateUserNotificationDto,
  ): Promise<void>;
  markAllAsRead(user_id: string): Promise<void>;
  getUrlResponseHeaders(url: string): Promise<UrlResponseHeadersDto>;
  shortioGetLinkInfo(url: string): Promise<ShortLinkInfoDto>;
}

export class UnifiedCommsService
  extends BaseApiService
  implements IUnifiedCommsService
{
  private unifiedCommsServiceClient: UnifiedCommsClient;
  constructor(
    baseUrl: string,
    config: AxiosRequestConfig = {},
    enableAuth = true,
    baseConfig?: BaseApiConfiguration,
  ) {
    super(baseUrl, config, enableAuth, baseConfig);
    this.unifiedCommsServiceClient = new UnifiedCommsClient(
      undefined,
      baseUrl,
      this.axiosInstance,
    );
  }

  async checkService(): Promise<any> {
    const response = await this.unifiedCommsServiceClient.appGetHealthCheck();
    return response.data;
  }

  async getAllConversationsByPatientAndLocation(
    locationId: string,
    locationPatientId: string,
    orderBy?: SupportedOrderBy,
    order?: Order,
    limit?: number,
  ): Promise<DirectMessagePatientDto[]> {
    const { data } =
      await this.unifiedCommsServiceClient.directMessageGetAllConversationsByPatientAndLocation(
        locationId,
        locationPatientId,
        orderBy,
        order,
        limit,
      );
    return data ?? [];
  }

  async getAllMessagesByConversation(
    locationId: string,
    locationPatientId: string,
    conversationId: string,
    skip?: number | undefined,
    take?: number,
  ): Promise<DirectMessageExtendedResponseDto> {
    const { data } =
      await this.unifiedCommsServiceClient.directMessageGetAllMessagesByConversation(
        locationId,
        locationPatientId,
        conversationId,
        skip,
        take,
      );
    return data;
  }

  async updateUserViewedStatus(
    locationId: string,
    locationPatientId: string,
    conversationId: string,
    updateUserViewedStatusDto: UpdateUserViewedStatusDto,
  ): Promise<ConversationDto> {
    const { data } =
      await this.unifiedCommsServiceClient.directMessageUpdateUserViewedStatus(
        locationId,
        locationPatientId,
        conversationId,
        updateUserViewedStatusDto,
      );
    return data;
  }

  async createConversation(
    xPharmacyId: string,
    locationId: string,
    locationPatientId: string,
    createConversationDto: CreateConversationDto,
  ): Promise<ConversationDto> {
    const { data } =
      await this.unifiedCommsServiceClient.directMessageCreateConversation(
        xPharmacyId,
        locationId,
        locationPatientId,
        createConversationDto,
      );
    return data;
  }

  async createMessage(
    xPharmacyId: string,
    locationId: string,
    locationPatientId: string,
    conversationId: string,
    addDirectMessageDto: AddDirectMessageDto,
  ): Promise<DirectMessageDto> {
    const { data } =
      await this.unifiedCommsServiceClient.directMessageAddMessage(
        xPharmacyId,
        locationId,
        locationPatientId,
        conversationId,
        addDirectMessageDto,
      );
    return data;
  }

  async getConversation(
    locationId: string,
    locationPatientId: string,
    conversationId: string,
  ): Promise<DirectMessagePatientDto> {
    const { data } =
      await this.unifiedCommsServiceClient.directMessageGetConversation(
        locationId,
        locationPatientId,
        conversationId,
      );
    return data;
  }

  async registerPushToken(user_id: string, push_token: string): Promise<void> {
    await this.unifiedCommsServiceClient.userPushTokenCreate(user_id, {
      push_token,
    });
  }

  async unregisterPushToken(
    user_id: string,
    push_token: string,
  ): Promise<void> {
    await this.unifiedCommsServiceClient.userPushTokenDelete(user_id, {
      push_token,
    });
  }

  async getNotifications(user_id: string): Promise<UserNotificationListDto> {
    const { data } =
      await this.unifiedCommsServiceClient.userNotificationFindByUserId(
        user_id,
      );
    return data;
  }

  async updateUserNotification(
    user_id: string,
    notification_id: string,
    updateUserNotificationDto: UpdateUserNotificationDto,
  ): Promise<void> {
    await this.unifiedCommsServiceClient.userNotificationUpdate(
      user_id,
      notification_id,
      updateUserNotificationDto,
    );
  }

  async markAllAsRead(user_id: string): Promise<void> {
    await this.unifiedCommsServiceClient.userNotificationMarkAllRead(user_id);
  }

  // Users can send random links via messages.
  // We have a feature to embed these external pages into our platform.
  // But some websites disallow to embed their content.
  // This endpoint we need for making a request to external random website to check if they have a header
  // that reflects disallowing to be embedded.
  // There is no possibility to check it on frontend.
  async getUrlResponseHeaders(url: string): Promise<UrlResponseHeadersDto> {
    return (
      await this.unifiedCommsServiceClient.directMessageGetUrlResponseHeaders(
        url,
      )
    ).data;
  }

  async shortioGetLinkInfo(shortLink: string): Promise<ShortLinkInfoDto> {
    const { data } =
      await this.unifiedCommsServiceClient.shortioGetLinkInfo(shortLink);
    return data;
  }

  async getDirectMessageSettings(pharmacyId: string, locationId: string) {
    const { data } =
      await this.unifiedCommsServiceClient.directMessageSettingsGet(
        pharmacyId,
        locationId,
      );
    return data;
  }
}

export default new UnifiedCommsService(
  UNIFIED_COMMS_SERVICE_BASE_URL,
  AXIOS_DEFAULT_REQUEST_CONFIG,
  true,
);
