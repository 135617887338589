import React, {
  ForwardedRef,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { View } from 'react-native';
import { useIsFocused } from '@react-navigation/native';
import {
  NewMessageHandler,
  NewMessageModalComponentProps,
  SubjectOptionsEnum,
} from './MessageProps';
import { PharmacyLocationDto } from '@digitalpharmacist/pharmacy-service-client-axios';
import { Form } from 'assets/layout';
import { getSpacing, makeStyles } from 'assets/theme';
import { StoreInformation } from '../../components/store-selector/StoreInformation';
import { useForm } from 'react-hook-form';
import { NewMessageForm } from './MessageProps';
import { StoreSelector } from '../../components/store-selector';
import { StoreSelectorHandler } from '../../components/store-selector/types';
import { useUserState } from '../../store/user-store';
import { useAppStateStore } from '../../store/app-store';
import { NewMessageRadioButton } from './NewMessageRadioButton';
import { Text } from 'assets/components/text';
import { getText } from 'assets/localization/localization';
import { AuthorType } from '@digitalpharmacist/unified-communications-service-client-axios';
import { TextField } from 'assets/components/text-field';
import { MESSAGE_LIMIT } from './constants';
import { usePatientRecordState } from '../account/patient/patient-store';
import { GenericModal } from 'assets/components/generic-modal/GenericModal';
import { BaseModalHandler } from 'assets/components/base-modal/BaseModal';
import { AttachFiles } from './AttachFiles';
import { useMessagesState } from './messages-store';
import { uploadAttachments } from './utils';
import { clearAttachments } from './messages-actions';

export const NewMessageModalComponent = forwardRef<
  NewMessageHandler,
  NewMessageModalComponentProps
>(
  (
    {
      handleMessageSend = async () => {},
      preSelectedSubject,
      preSelectedMessage,
      locationId,
      isPatientStartChatAvailable,
    }: NewMessageModalComponentProps,
    ref: ForwardedRef<NewMessageHandler>,
  ) => {
    const modalRef = useRef<BaseModalHandler>(null);
    const [currentStore, setCurrentStore] = useState<PharmacyLocationDto>();
    const storeSelectorRef = useRef<StoreSelectorHandler>(null);
    const user = useUserState((state) => state.user);
    const updateUser = useUserState((state) => state.updateUser);
    const storeId = locationId || user?.preferredPharmacyLocationId;
    const isFocused = useIsFocused();
    const stores = useAppStateStore((state) => state.stores);
    const styles = useStyles();
    const patientRecord = usePatientRecordState((state) => state.patientRecord);
    const [isMessageSending, setIsMessageSending] = useState(false);
    const newMessageAttachmentsDocuments = useMessagesState(
      (state) => state.newMessageAttachmentsDocuments,
    );
    const newMessageAttachmentsImages = useMessagesState(
      (state) => state.newMessageAttachmentsImages,
    );

    const attachmentsCount =
      newMessageAttachmentsDocuments.length +
      newMessageAttachmentsImages.length;

    useEffect(() => {
      setCurrentStore(stores.find((x) => x.id === storeId));
    }, [storeId, stores, isFocused]);

    const methods = useForm<NewMessageForm>({
      defaultValues: {
        message: preSelectedMessage ?? '',
        subject: preSelectedSubject
          ? preSelectedSubject
          : SubjectOptionsEnum.General,
      },
    });

    const handleShowModal = () => {
      modalRef.current?.show();
    };

    const handleDismissModal = () => {
      modalRef.current?.hide();

      // I hate using setTimeout, but this is so the
      // patient doesn't see the fields reset when sending
      setTimeout(() => {
        methods.reset();
      }, 1000);
    };

    useImperativeHandle(ref, () => ({
      show: handleShowModal,
      hide: handleDismissModal,
    }));

    const handleStoreSelectorChange = () => {
      storeSelectorRef.current?.show();
    };

    const handleOnLocationChanged = (value: PharmacyLocationDto) => {
      updateUser({ preferredPharmacyLocationId: value.id });
    };

    const handleOnMessageChange = (value: string) => {
      methods.setValue('message', value);
    };

    return (
      <GenericModal
        isScrollable
        webModalSize="sm"
        showDismissButton
        title={getText('new-message-uppercase')}
        ref={modalRef}
        buttons={[
          {
            text: getText('send'),
            onPress: methods.handleSubmit(async (data) => {
              if (
                !user ||
                !currentStore ||
                !patientRecord ||
                !locationId ||
                !user?.pharmacyId
              ) {
                console.error('No needed data to create a conversation');
                return;
              }

              setIsMessageSending(true);
              const filesUploadResult = await uploadAttachments({
                locationId: locationId,
                pharmacyId: user.pharmacyId,
                newMessageAttachmentsDocuments: newMessageAttachmentsDocuments,
                newMessageAttachmentsImages: newMessageAttachmentsImages,
              });

              await handleMessageSend(
                user.pharmacyId,
                currentStore.id,
                patientRecord,
                {
                  author_id: user.id,
                  author_type: AuthorType.Patient,
                  patient_viewed_all_messages: true,
                  pharmacy_viewed_all_messages: false,
                  subject: data.subject,
                  content: data.message,
                  attachments: filesUploadResult.filesData.length
                    ? filesUploadResult.filesData
                    : [],
                },
                user.preferredPharmacyLprId,
              );

              clearAttachments();
              setIsMessageSending(false);
            }),
            logger: { id: 'new-message-modal-send-button' },
            hierarchy: 'primary',
            disabled: isMessageSending,
          },
          {
            onPress: handleDismissModal,
            logger: { id: 'new-message-modal-cancel-button' },
            text: getText('cancel'),
            hierarchy: 'secondary',
          },
        ]}
      >
        <View style={styles.container}>
          <Form methods={methods}>
            <Form.Alert
              visible={
                !!methods.getFieldState('message').error ||
                !!methods.getFieldState('subject').error
              }
              title={getText('unable-to-process-complete-required-fields')}
              intent="error"
            />
            <Form.Alert
              visible={!isPatientStartChatAvailable}
              title={getText('messages-reload-application')}
              intent="error"
            />
            {currentStore && (
              <View style={styles.currentStoreContainer}>
                <StoreInformation
                  showPhone={false}
                  showChange={true}
                  item={currentStore}
                  onChangePress={handleStoreSelectorChange}
                />
              </View>
            )}
            <TextField
              name="message"
              multiline={true}
              numberOfLines={5}
              placeholder={getText('new-message-placeholder')}
              onChange={(value) => handleOnMessageChange(value)}
              maxLength={MESSAGE_LIMIT}
              rules={{
                validate: () => {
                  const message = methods.watch('message') || '';
                  if (message.length === 0 && attachmentsCount === 0) {
                    return `${getText('message-body-is-required')}.`;
                  }
                  return true;
                },
              }}
            />
            <View style={styles.messageLimitWrapper}>
              <Text style={styles.messageLimit}>{`${
                methods.watch('message').length
              }/${MESSAGE_LIMIT}`}</Text>
            </View>
            <AttachFiles />
            {preSelectedSubject === undefined && (
              <>
                <View>
                  <Text>{getText('message-subject-label')}</Text>
                </View>
                <View style={styles.radioButtonsContainer}>
                  <View style={styles.radioButtonsInnerContainer}>
                    <NewMessageRadioButton
                      selected={
                        methods.watch('subject') === SubjectOptionsEnum.General
                      }
                      text={getText('general')}
                      value={SubjectOptionsEnum.General}
                      onPress={() => {
                        methods.setValue('subject', SubjectOptionsEnum.General);
                      }}
                      disabled={
                        preSelectedSubject
                          ? preSelectedSubject !== SubjectOptionsEnum.General
                          : undefined
                      }
                    />
                    <NewMessageRadioButton
                      selected={
                        methods.watch('subject') ===
                        SubjectOptionsEnum.MedicationInfo
                      }
                      text={getText('med-info')}
                      value={SubjectOptionsEnum.MedicationInfo}
                      onPress={() => {
                        methods.setValue(
                          'subject',
                          SubjectOptionsEnum.MedicationInfo,
                        );
                      }}
                      disabled={
                        preSelectedSubject
                          ? preSelectedSubject !==
                            SubjectOptionsEnum.MedicationInfo
                          : undefined
                      }
                    />
                    <NewMessageRadioButton
                      selected={
                        methods.watch('subject') ===
                        SubjectOptionsEnum.Appointments
                      }
                      text={getText('appointments')}
                      value={SubjectOptionsEnum.Appointments}
                      onPress={() => {
                        methods.setValue(
                          'subject',
                          SubjectOptionsEnum.Appointments,
                        );
                      }}
                      disabled={
                        preSelectedSubject
                          ? preSelectedSubject !==
                            SubjectOptionsEnum.Appointments
                          : undefined
                      }
                    />
                  </View>
                  <View style={styles.radioButtonsInnerContainer}>
                    <NewMessageRadioButton
                      selected={
                        methods.watch('subject') === SubjectOptionsEnum.Billing
                      }
                      text={getText('billing')}
                      value={SubjectOptionsEnum.Billing}
                      onPress={() => {
                        methods.setValue('subject', SubjectOptionsEnum.Billing);
                      }}
                      disabled={
                        preSelectedSubject
                          ? preSelectedSubject !== SubjectOptionsEnum.Billing
                          : undefined
                      }
                    />
                    <NewMessageRadioButton
                      selected={
                        methods.watch('subject') ===
                        SubjectOptionsEnum.SideEffects
                      }
                      text={getText('side-effects')}
                      value={SubjectOptionsEnum.SideEffects}
                      onPress={() => {
                        methods.setValue(
                          'subject',
                          SubjectOptionsEnum.SideEffects,
                        );
                      }}
                      disabled={
                        preSelectedSubject
                          ? preSelectedSubject !==
                            SubjectOptionsEnum.SideEffects
                          : undefined
                      }
                    />
                  </View>
                </View>
              </>
            )}
          </Form>
          <StoreSelector
            ref={storeSelectorRef}
            options={stores}
            selectedOption={stores.find(
              (x) => x.id === user?.preferredPharmacyLocationId,
            )}
            onChange={handleOnLocationChanged}
            storeInformationShown={false}
          />
        </View>
      </GenericModal>
    );
  },
);

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: getSpacing(1),
  },
  currentStoreContainer: {
    paddingBottom: getSpacing(3),
    paddingLeft: getSpacing(1),
  },
  radioButtonsContainer: {
    paddingTop: getSpacing(2),
    flexDirection: 'row',
  },
  radioButtonsInnerContainer: {
    flex: 1,
    flexDirection: 'column',
  },
  messageLimitWrapper: {
    width: '100%',
    alignItems: 'flex-end',
    marginTop: getSpacing(0.5),
    marginBottom: getSpacing(3),
  },
  messageLimit: {
    color: theme.palette.gray[500],
  },
}));
