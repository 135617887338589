import { logError, logEvent } from 'assets/logging/logger';
import { useUserState } from '../../store/user-store';
import { useAppStateStore } from '../../store/app-store';
import { useLoginState } from './login-store';
import { getText } from 'assets/localization/localization';
import { StorageKeys } from '../../../enums/storage-keys';
import { User } from '@sentry/react-native';
import UsersServiceInstance from '../../api/users-service';
import {
  NotificationType,
  PatientUserDto,
  UserLogin,
  UserLoginResponse,
} from '@digitalpharmacist/users-service-client-axios';
import { LoginStackNavigationProp } from '../../navigation/LoginNavigation';
import { ampli } from '../../common/ampliPatient';
import { resetAddPrescriptionState } from '../add-medications/add-medications-actions';
import usersService from '../../api/users-service';
import Constants from 'expo-constants';
import axios from 'axios';
import { resetRefillMedicationState } from '../refill/refill-store';
import { usePatientRecordState } from '../account/patient/patient-store';
import AsyncStorageService from '../../api/async-storage-service';
import { loadPatientData } from '../../actions/app-actions';
import * as Notifications from 'expo-notifications';
import unifiedCommsService from '../../api/unified-comms-service';
import { Platform } from 'react-native';
import * as Device from 'expo-device';
import { useAppNavigation } from '../../providers/app-navigation';
import { CommonActions, ParamListBase } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';

export const login = async (
  values: UserLogin,
  navigation: LoginStackNavigationProp,
): Promise<void> => {
  const pharmacyId = useAppStateStore.getState().pharmacyId;
  useLoginState.setState({ error: undefined, status: 'loading' });
  useLoginState.setState({ email: values.email?.trim() });

  try {
    values.pharmacyId = pharmacyId;

    const userLoginResponse: UserLoginResponse =
      await UsersServiceInstance.logIn(values);
    if (!userLoginResponse.accessToken) {
      throw new Error(getText('email-or-password-incorrect'));
    }
    ampli.userLoggedIn({
      biometricAuthenticationMethod: 'none',
      loginMethod: 'email',
      loginStatus: 'Logged In',
      loginTime: new Date().toISOString(),
      productVersion: Constants.expoConfig?.version!,
    });

    await AsyncStorageService.setItem(
      StorageKeys.AccessToken,
      userLoginResponse.accessToken,
    );

    await AsyncStorageService.setItem(
      StorageKeys.RefreshToken,
      userLoginResponse.refreshToken,
    );

    useLoginState.setState({ status: 'success' });
    if (!userLoginResponse.patient_user) {
      throw new Error(getText('email-or-password-incorrect'));
    }
    const user: PatientUserDto = userLoginResponse.patient_user;
    if (user.id) {
      await AsyncStorageService.setItem(StorageKeys.UserId, user.id);
      useUserState.setState({
        user: user,
      });
    } else {
      throw new Error(getText('email-or-password-incorrect'));
    }

    await loadPatientData();
  } catch (e) {
    ampli.userLoggedIn({
      biometricAuthenticationMethod: 'none',
      loginMethod: 'email',
      loginStatus: 'Log in Failed',
      loginTime: new Date().toISOString(),
      productVersion: Constants.expoConfig?.version!,
    });
    if (axios.isAxiosError(e) && e.response?.status === 404) {
      navigation.navigate('account-not-found');
    } else if (axios.isAxiosError(e) && e.response?.status === 403) {
      navigation.navigate('login-attempts-exceeded');
    } else {
      useLoginState.setState({
        error: {
          message: getText('email-or-password-incorrect'),
        },
        status: 'error',
      });
    }
  }
};

export const checkUserStatus = async (
  email: string,
  navigation: LoginStackNavigationProp,
  queryParams?: any,
): Promise<void> => {
  try {
    useLoginState.setState({ error: undefined, status: 'loading' });
    const pharmacyId = useAppStateStore.getState().pharmacyId;
    const data = await UsersServiceInstance.checkUser(email.trim(), pharmacyId);
    // TODO we need to handle one more case:
    //the case where user exists in  dp1
    if (!data.dp1 && !data.dp2) {
      navigation.navigate('register-confirmation', queryParams);
    } else if (!data.dp1 && data.dp2) {
      useLoginState.setState({ email: email.trim() });
      navigation.navigate('login', queryParams);
    } else if (data.dp1 && !data.dp2) {
      navigation.navigate('register-confirmation', queryParams);
    }
    useLoginState.setState({ status: 'success' });
  } catch (e) {
    useLoginState.setState({
      error: {
        message: e as string,
      },
      status: 'error',
    });
  }
};

export const resetNavigationState = () => {
  const navigation = useAppNavigation<StackNavigationProp<ParamListBase>>();
  const prefix = useAppStateStore.getState().slug;
  const resetAction = CommonActions.reset({
    index: 0,
    routes: [
      {
        name: '/',
        params: {
          prefix: prefix,
        },
      },
    ],
  });
  navigation?.dispatch(resetAction);
};

export const logout = async (): Promise<void> => {
  try {
    if (Platform.OS !== 'web' && Device.isDevice) {
      await unregisterPushToken();
    }
    await AsyncStorageService.clear();
    useUserState.setState({
      user: undefined,
    });
    useAppStateStore.setState({
      notifications: [],
      notificationsBadgeCount: 0,
    });
    usePatientRecordState.setState({
      patientRecord: undefined,
      patientRecordItems: [],
    });
    resetAddPrescriptionState();
    resetRefillMedicationState();
    await Notifications.setBadgeCountAsync(0);
    ampli.userLoggedOut({
      logoutMethod: 'logout',
      logoutTime: new Date().toISOString(),
    });
    resetNavigationState();
  } catch (error: any) {
    ampli.userLoggedOut({
      logoutMethod: 'logout failed',
      logoutTime: new Date().toISOString(),
    });
    logError(error);
  }
};

export const unregisterPushToken = async () => {
  try {
    const user = useUserState.getState().user;
    const { data } = await Notifications.getExpoPushTokenAsync({
      projectId: Constants.expoConfig?.extra?.eas.projectId,
    });

    if (!user || !data) {
      logError(
        new Error(
          'User id or push token is undefined when attempting to unregister',
        ),
      );
      return;
    }

    await unifiedCommsService.unregisterPushToken(user.id, data);
  } catch (error) {
    logError(error as Error);
  }
};

export const googleLogin = async (
  idToken: string,
  accessToken: string,
  navigation: LoginStackNavigationProp,
): Promise<void> => {
  useLoginState.setState({ error: undefined, status: 'loading' });

  try {
    const response = await usersService.googleLogin({ idToken, accessToken });
    await AsyncStorageService.setItem(
      StorageKeys.AccessToken,
      response.accessToken,
    );
    //TODO: Add this back when backend starts returning refresh token for sso
    //await AsyncStorageService.setItem(StorageKeys.RefreshToken, response.refreshToken);
    await AsyncStorageService.setItem(
      StorageKeys.UserId,
      response.patient_user.id,
    );

    useUserState.setState({
      user: response.patient_user,
    });
    useLoginState.setState({ status: 'success' });

    ampli.userLoggedIn({
      biometricAuthenticationMethod: 'none',
      loginMethod: 'Google',
      loginStatus: 'Logged In',
      loginTime: new Date().toISOString(),
      productVersion: Constants.expoConfig?.version!,
    });

    await loadPatientData();
  } catch (e) {
    if (axios.isAxiosError(e) && e.response?.status === 404) {
      navigation.navigate('account-not-found', { isSSO: true });
      useLoginState.setState({
        status: 'idle',
      });
    } else {
      useLoginState.setState({
        error: { message: getText('google-login-failed') },
        status: 'error',
      });
    }
    logEvent('google_login_failed', { error: e });
    ampli.userLoggedIn({
      biometricAuthenticationMethod: 'none',
      loginMethod: 'Google',
      loginStatus: 'Google login failed',
      loginTime: new Date().toISOString(),
      productVersion: Constants.expoConfig?.version!,
    });
  }
};

export const sendNotificationWhenAccountLocked = async (
  email: string,
  pharmacyId: string,
  notificationType: NotificationType,
  navigation: LoginStackNavigationProp,
): Promise<void> => {
  useLoginState.setState({ error: undefined, status: 'loading' });
  try {
    await usersService.sendNotificationWhenAccountLocked(
      email.trim(),
      notificationType,
      pharmacyId,
    );
    useLoginState.setState({ status: 'success' });
    navigation.navigate('login-enter-email'); // Redirected to '/login-enter-email' until the product gives me the right information
  } catch (error) {
    useLoginState.setState({
      error: { message: getText('something-went-wrong-implicit') }, // Used default error message. To be changed if it is not the right one
      status: 'error',
    });
  }
};

export interface LoginForm {
  email: string;
  password: string;
  pharmacyId: string;
}

export interface UserToken {
  accessToken: string;
  idToken: string;
  userInfo: User;
}
