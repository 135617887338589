import { Icon } from 'assets/components/icon';
import { Text } from 'assets/components/text';
import { NoMessagesIcon } from 'assets/icons';
import { getText } from 'assets/localization/localization';
import theme, { makeStyles } from 'assets/theme';
import React, { FunctionComponent, PropsWithChildren } from 'react';
import { View } from 'react-native';

export const MessagesEmptyState: FunctionComponent<PropsWithChildren<{}>> = ({
  children,
}) => {
  const styles = useStyles();

  return (
    <View style={styles.container}>
      <View style={styles.emptyStateContainer}>
        <View style={{ alignItems: 'center' }}>
          <Icon icon={NoMessagesIcon} />
        </View>
        <Text style={styles.heading}>{getText('no-messages')}</Text>
        <Text style={styles.subheading}>
          {getText('messages-have-a-question')}
        </Text>
        {children}
      </View>
    </View>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    flex: 1,
  },
  label: {
    color: theme.palette.gray[500],
    marginBottom: theme.getSpacing(0.5),
  },
  locationContainer: {
    marginTop: theme.getSpacing(2),
    marginLeft: theme.getSpacing(1),
  },
  emptyStateContainer: {
    flex: 1,
    alignSelf: 'center',
    justifyContent: 'center',
    width: '70%',
  },
  heading: {
    ...theme.lumistryFonts.text.large.semiBold,
    textAlign: 'center',
    fontWeight: '600',
    marginVertical: theme.getSpacing(2),
  },
  subheading: {
    ...theme.lumistryFonts.text.small.regular,
    textAlign: 'center',
    color: theme.palette.gray[700],
  },
  pharmacyNameContainer: {
    flexDirection: 'row',
  },
  pharmacyName: {
    color: theme.palette.gray[500],
  },
}));
