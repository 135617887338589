import React, {
  useEffect,
  useState,
  FunctionComponent,
  PropsWithChildren,
} from 'react';
import { View } from 'react-native';
import { CheckboxInput } from '../checkbox';
import { Tag } from '../tag';
import { Typeahead } from '../typeahead';
import { TypeaheadBaseItem, TypeaheadProps } from '../typeahead/types';
import { uniqueWithMap } from '../typeahead/utils';
import { Text } from '../text';
import { makeStyles, useTheme } from '../../theme';

export interface TypeaheadWithTagsProps
  extends TypeaheadProps<string | TypeaheadBaseItem> {
  tags?: Array<string | TypeaheadBaseItem>;
  testID?: string;
}

export const TypeaheadWithTags: FunctionComponent<
  PropsWithChildren<TypeaheadWithTagsProps>
> = (props) => {
  const theme = useTheme();
  const styles = useStyles();
  // in case it is not supplied from the developer lets fallback to the default value
  const getOptionValueFallback = (option: any) => option.value ?? option;
  const getOptionValue = props.getOptionValue || getOptionValueFallback;

  // in case it is not supplied from the developer lets fallback to the default value
  const getOptionTextFallback = (option: any) => option.text ?? option;
  const getOptionText = props.getOptionText || getOptionTextFallback;

  const [selectedValues, setSelectedValues] = useState(props.defaultValue);
  const [checkboxChecked, setCheckboxChecked] = useState(false);

  useEffect(() => {
    if (!props.defaultValue) return;
    const defaultValuesContainsEmptyValue =
      props.emptyValue &&
      !!props.defaultValue.find(
        (x) => getOptionValue(x) === getOptionValue(props.emptyValue as any),
      );
    if (defaultValuesContainsEmptyValue) {
      setSelectedValues([props.emptyValue!]);
      setCheckboxChecked(true);
    } else {
      setSelectedValues(props.defaultValue);
      setCheckboxChecked(false);
    }
  }, [props.defaultValue]);

  const onChanged = (value: (string | TypeaheadBaseItem)[]) => {
    let newValue = value;
    if (props.emptyValue)
      newValue = newValue.filter(
        (x) => getOptionValue(x) !== getOptionValue(props.emptyValue as any),
      );

    setCheckboxChecked(false);
    setSelectedValues(newValue);
    props.onChange?.(newValue);
  };

  const onSuggestionPressed = (value: string | TypeaheadBaseItem) => {
    let newSelectedValue: Array<any> = [];
    const currentValueExist = selectedValues?.filter(
      (x) => getOptionValue(x) === getOptionValue(value),
    );
    if (currentValueExist && currentValueExist?.length > 0) {
      // remove it from selected values
      newSelectedValue =
        selectedValues?.filter(
          (x) => getOptionValue(x) !== getOptionValue(value),
        ) || [];
    } else {
      newSelectedValue = !props.multiple
        ? [value]
        : (uniqueWithMap(
            [...(selectedValues || []), value],
            getOptionValue,
          ) as any[]);
    }

    if (props.emptyValue)
      newSelectedValue = newSelectedValue.filter(
        (x) => getOptionValue(x) !== getOptionValue(props.emptyValue as any),
      );

    setCheckboxChecked(false);
    setSelectedValues(newSelectedValue);
    props.onChange?.(newSelectedValue);
  };

  const handleNoAllergiesCheckboxPress = (checked: boolean) => {
    if (!props.emptyValue) return;

    const newValue = checked ? [props.emptyValue] : [];

    setCheckboxChecked(checked);
    setSelectedValues(newValue);
    props.onChange?.(newValue);
  };

  return (
    <View testID={props.testID}>
      {props.label && <Text style={styles.label}>{props.label}</Text>}
      <Typeahead
        {...props}
        defaultValue={selectedValues?.filter((x) =>
          props.emptyValue
            ? getOptionValue(x) !== getOptionValue(props.emptyValue as any)
            : true,
        )}
        // forcing empty value to not be shown
        // because will be considered the checkbox above
        emptyValue={undefined}
        // same for the label as the comment above
        label={undefined}
        onChange={onChanged}
        disabled={props.disabled || checkboxChecked}
      />
      <View style={styles.tagContainer}>
        {props.tags?.map((x, i) => (
          <Tag
            key={i}
            testID={getOptionValue(x) ?? +i}
            onPress={() => onSuggestionPressed(x)}
            label={getOptionText(x)}
            selected={
              !!selectedValues?.find(
                (s) => getOptionValue(s) === getOptionValue(x),
              )
            }
            style={styles.tag}
            disabled={props.disabled || checkboxChecked}
            textProps={{ ellipsizeMode: 'tail', numberOfLines: 1 }}
          />
        ))}
      </View>
    </View>
  );
};

const useStyles = makeStyles((theme) => ({
  label: {
    marginLeft: theme.getSpacing(0.5),
    marginBottom: theme.getSpacing(1),
  },
  tagContainer: {
    flexDirection: 'row',
    flex: 1,
    flexWrap: 'wrap',
  },
  tag: {
    height: 28,
    marginRight: theme.getSpacing(1),
    marginTop: theme.getSpacing(1),
  },
}));
