import { Linking, Platform } from 'react-native';
import { DeviceType, getDeviceTypeAsync } from 'expo-device';
import { Location } from '../types';
import {
  OrderStatus,
  OrderProgressStatus,
} from '@digitalpharmacist/order-service-client-axios';
import { getText, translations } from 'assets/localization/localization';
import PatientService from '../api/patient-service';
import { PatientRecordDto } from '@digitalpharmacist/patient-service-client-axios';
import {
  NavigationProp,
  ParamListBase,
  Route,
  useNavigation,
} from '@react-navigation/native';
import { useAppStateStore } from '../store/app-store';
import * as ImagePicker from 'expo-image-picker';
import * as FileSystem from 'expo-file-system';
import { useUserState } from '../store/user-store';
import { logError } from 'assets/logging/logger';
import UnifiedCommsService from '../api/unified-comms-service';
import { findHeaderThatDisallowsEmbedding } from '../screens/messages/utils';

/**
 * Calculates the haversine distance between point A, and B.
 * @param {Location} first point A
 * @param {Location} second point B
 * @param {boolean} isMiles If we are using miles, else km.
 */

export const haversineDistance = (
  first: Location,
  second: Location,
  unit: 'km' | 'mi' = 'mi',
) => {
  const toRadian = (angle: number) => (Math.PI / 180) * angle;
  const distance = (a: number, b: number) => (Math.PI / 180) * (a - b);
  const RADIUS_OF_EARTH_IN_KM = 6371;

  const dLat = distance(second.latitude!, first.latitude!);
  const dLon = distance(second.longitude!, first.longitude!);

  const lat1 = toRadian(first.latitude!);
  const lat2 = toRadian(second.latitude!);

  // Haversine Formula
  const a =
    Math.pow(Math.sin(dLat / 2), 2) +
    Math.pow(Math.sin(dLon / 2), 2) * Math.cos(lat1) * Math.cos(lat2);
  const c = 2 * Math.asin(Math.sqrt(a));

  let finalDistance = RADIUS_OF_EARTH_IN_KM * c;

  if (unit === 'mi') {
    finalDistance /= 1.60934;
  }

  return finalDistance;
};

export const getDefaultGreeting = () => {
  const currentTime = new Date();
  const currentHour = currentTime.getHours();
  const currentMinute = currentTime.getMinutes();

  //This is here so that the greeting is "good evening" until 12:01
  if (currentHour === 0 && currentMinute < 1) {
    return getText('good-evening');
  } else if (currentHour === 0 && currentMinute >= 1) {
    return getText('good-morning');
  } else if (currentHour < 12) {
    return getText('good-morning');
  } else if (currentHour < 18) {
    return getText('good-afternoon');
  } else {
    return getText('good-evening');
  }
};

export const notImplementedAlert = () => {
  alert('Under construction. Exciting things coming soon!');
};

export const formatPhoneNumber = (phoneNumber: string) => {
  return phoneNumber
    .replace('+1', '')
    .replace('+', '')
    .replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
};

export const formatPhone = (phoneNumber: string) => {
  let cleanPrefixPhone = phoneNumber.replace('+1', '');
  cleanPrefixPhone = cleanPrefixPhone.replace(/\D/g, '');
  return cleanPrefixPhone.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
};

export const isMobileOrTabletBrowser = async () => {
  const deviceType = await getDeviceTypeAsync();
  return (
    Platform.OS === 'web' &&
    (deviceType === DeviceType.PHONE || deviceType === DeviceType.TABLET)
  );
};

export const isMobileBrowser = async () => {
  const deviceType = await getDeviceTypeAsync();
  return Platform.OS === 'web' && deviceType === DeviceType.PHONE;
};

export const getOrderStatusText = (status: OrderStatus | undefined | null) => {
  return getGenericStatusText(status, 'order-status-');
};

export const getOrderProgressText = (status: OrderProgressStatus) => {
  return getGenericStatusText(status, 'order-progress-');
};

const getGenericStatusText = (
  status: string | undefined | null,
  keyPrefix: string,
  keyPostfix = '',
) => {
  if (!status) status = 'undefined';

  const normalizeStatus = status.replaceAll('_', '-');

  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  const textKey = `${keyPrefix}${normalizeStatus}${keyPostfix}` as translations;

  const rxStatusText = getText(textKey);

  if (!rxStatusText) return textKey;

  return rxStatusText;
};

export const createLprIfNotExist = async (
  locationPatientId: string | undefined,
  locationId: string,
  patientRecord: PatientRecordDto,
) => {
  if (!locationPatientId) {
    const newPatientRecord =
      await PatientService.patientRecordInitLocationPatientRecord(
        patientRecord.id,
        {
          location_id: locationId,
        },
      );

    const finalLocationPatientId =
      newPatientRecord.location_patient_records.find(
        (record) => record.location_id === locationId,
      )?.id;

    if (!finalLocationPatientId) {
      throw new Error('Failed to create location patient record');
    }

    const { user } = useUserState.getState();
    if (user) {
      useUserState.setState({
        user: { ...user, preferredPharmacyLprId: finalLocationPatientId },
      });
    }

    return finalLocationPatientId;
  } else {
    return locationPatientId;
  }
};

export const titleFormatter = (
  options: Record<string, any> | undefined,
  route: Route<string> | undefined,
): string => {
  const { pharmacyName } = useAppStateStore.getState();
  // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
  return `${options?.title ?? route?.name ?? 'Loading'} - ${pharmacyName}`;
};

export const imagePickerUtils = {
  async getImagesSizeInWeb(assets: ImagePicker.ImagePickerAsset[]) {
    let totalSize = 0;
    for (const image of assets) {
      const blob = await fetch(image.uri).then((r) => r.blob());
      if (blob) {
        totalSize += blob.size;
      }
    }

    return totalSize;
  },

  async getImagesSizeInMobile(assets: ImagePicker.ImagePickerAsset[]) {
    let totalSize = 0;
    for (const image of assets) {
      const fileInfo: FileSystem.FileInfo = await FileSystem.getInfoAsync(
        image.uri,
      );

      if (fileInfo.exists) {
        totalSize += fileInfo.size;
      }
    }

    return totalSize;
  },
};

export const openLinkInBrowser = (url: string) => {
  if (url) {
    Linking.canOpenURL(url)
      .then((supported) => {
        if (supported) {
          Linking.openURL(url);
        } else {
          logError(new Error('Unable to open URL.'));
        }
      })
      .catch((error) => logError(error));
  }
};

export const checkIfNotAllowedToEmbedWebDataFrom = async (
  url: string,
): Promise<boolean> => {
  try {
    // Users can send random links via messages.
    // We have a feature to embed these external pages into our platform.
    // But some websites disallow to embed their content.
    // This endpoint we need for making a request to external random website to check if they have a header
    // that reflects disallowing to be embedded.
    // There is no possibility to check it on frontend.
    const headers = (await UnifiedCommsService.getUrlResponseHeaders(
      url,
    )) as Record<string, string>;
    const disallowingHeader = findHeaderThatDisallowsEmbedding(headers);
    return Boolean(disallowingHeader);
  } catch (error) {
    console.error('Error fetching headers:', error);
    return true;
  }
};

export const handleAppointmentLinkClick = (link: string) => {
  const navigation = useNavigation<NavigationProp<ParamListBase>>();
  const url = new URL(link);
  const appointmentId = url.searchParams.get('appointment_id');
  const location_id = url.searchParams.get('location_id') || undefined;

  navigation.navigate('appointment', {
    appointment_id: appointmentId,
    location_id: location_id,
  });
};
